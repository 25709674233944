import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { List, ListItemButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth } from '../components/AuthContextProvider';
import { BrowserRouter as Router, Route } from "react-router-dom";
//import { LoginItem } from '../../App';
import { MyComponentProps } from '../types/TypeDefinition'
import { RoleType } from '../types/TypeDefinition';

export const MainTemplate2: React.FC<MyComponentProps> = ({ children }) => {
    const { setLoginItem, handleLogout } = useAuth();
    const { loginItem } = useAuth();

    //const handleLogout = async (e) => {
    //    e.preventDefault();

    //    window.localStorage.removeItem(LoginItem);
    //    setLoginItem("");
    //}

    return (
        <div>
            <nav className="fixed top-0 z-50 w-full bg-green-650 border-b 
                border-gray-200 shadow-md">
                <div className="p-1">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center justify-start">
                            <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar"
                                aria-controls="logo-sidebar" type="button"
                                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden
                             hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
                                <span className="sr-only">Open sidebar</span>
                                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                                </svg>
                            </button>

                            {/* Home */}
                            {/*  <p>
                                <a href="/home" class="h-9 w-9 p-1 inline-flex items-center text-base 
                                    rounded-lg text-gray-200">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" 
                                        stroke-width="1.5" stroke="currentColor" 
                                        class="w-8 h-8 hover:stroke-white">
                                        <title>ホーム</title>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                    </svg>
                                </a>
                            </p> */}

                            <p className="px-3 text-gray-100 text-lg hover:text-white">
                                <Link to="/home" title='ホーム'>fカルテット顧客管理</Link>
                            </p>

                        </div>
                        <div className="flex items-center">
                            <div className="flex items-center ml-3">
                                <div>
                                    {/* ログアウト */}
                                    <button type="button" className="flex text-sm rounded-lg"
                                        onClick={handleLogout}>
                                        <svg className="h-9 w-9 p-1 stroke-gray-200 hover:stroke-white"
                                            strokeWidth="32"
                                            fill="none" viewBox="0 0 512 512">
                                            <title>ログアウト</title>
                                            <path d="M304 336v40a40 40 0 01-40 40H104a40 40 0 01-40-40V136a40 40 0 0140-40h152c22.09 0 48 17.91 48 40v40M368 336l80-80-80-80M176 256h256"
                                                strokeLinecap="round" strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            <aside id="logo-sidebar"
                className="fixed top-0 left-0 z-40 w-48 h-screen pt-14 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700" aria-label="Sidebar">
                <div className="h-full px-3 pb-4 overflow-y-auto bg-white">
                    <ul className="space-y-2">
                        <li>
                            <Link to="/medical-facility-list"
                                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg 
                                hover:bg-gray-100">
                                <span>医療機関一覧</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/alert-list"
                                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg 
                                hover:bg-gray-100">
                                <span>監視結果アラート</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/monitoring-result-list"
                                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg 
                                hover:bg-gray-100">
                                <span>サーバー監視結果</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/fkartet-backup-list"
                                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg 
                                hover:bg-gray-100">
                                <span>バックアップ結果</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/fkartet-setting-list"
                                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg 
                                hover:bg-gray-100">
                                <span>fカルテット設定</span>
                            </Link>
                        </li>

                        {loginItem.roleId === RoleType.Admin &&
                            <li>
                                <Link to="/admin-setting"
                                    className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg 
                                hover:bg-gray-100">
                                    <span>システム設定</span>
                                </Link>
                            </li>
                        }
                    </ul>
                </div>
            </aside>


            <div className="ml-48 mt-14">
                {children}
            </div>
        </div>
    );
}