import React, { ChangeEvent, FormEvent, useState } from "react";
import { Button, Box, Card, Container, CssBaseline, Typography, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LoginItemKey, useAuth } from "../components/AuthContextProvider";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Avatar from "@mui/material/Avatar";
import { useNavigate, useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { MyComponentProps } from "../types/TypeDefinition";
import axios from "axios";
import * as Routes from "../ApiRoute"; 

type LoginRequest = {
    userId: string,
    password: string
}

// ログイン
export const Login: React.FC<MyComponentProps> = () => {
    const {setLoginItem, handleLogout} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [message, setMessage] = useState("");
    const [loginRequest, setLoginRequest] 
        = useState<LoginRequest>({ userId: '', password: '' });

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!loginRequest.userId || !loginRequest.password) {
            setMessage("ログインIDとパスワードを入力してください。");
            return;
        }

        const response = await axios.post(Routes.Authentication.Login, loginRequest)
        if (response.data) {
            setLoginItem(response.data);
            // ローカルストレージに保存
            window.localStorage.setItem(LoginItemKey, JSON.stringify(response.data));

            const origin = (location.state as any)?.from?.pathname || '/home';
            navigate(origin);
        }
        else {
            handleLogout();

            setMessage("ログインIDまたはパスワードが違います。");
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setMessage("");
        setLoginRequest((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            };
        });
    };

    return (
        <div className="flex justify-center">
            <div className="mt-20 mb-10 mx-10 md:mx-auto w-full max-w-md
                 bg-green-20 border rounded-lg shadow">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <div className="flex justify-center">
                        <div className="font-sans text-xl">fカルテット 顧客管理</div>
                    </div>

                    <div className="flex justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" height="48" 
                        viewBox="0 -960 960 960" width="48">
                            <path d="M222-255q63-44 125-67.5T480-346q71 0 
                            133.5 23.5T739-255q44-54 62.5-109T820-480q0-145-97.5-242.5T480-820q-145 
                            0-242.5 97.5T140-480q0 61 19 116t63 109Zm257.814-195Q422-450 
                            382.5-489.686q-39.5-39.686-39.5-97.5t39.686-97.314q39.686-39.5 
                            97.5-39.5t97.314 39.686q39.5 39.686 39.5 97.5T577.314-489.5q-39.686 
                            39.5-97.5 39.5Zm.654 370Q398-80 
                            325-111.5q-73-31.5-127.5-86t-86-127.266Q80-397.532 
                            80-480.266T111.5-635.5q31.5-72.5 86-127t127.266-86q72.766-31.5 
                            155.5-31.5T635.5-848.5q72.5 31.5 127 86t86 127.032q31.5 72.532 31.5 
                            155T848.5-325q-31.5 73-86 127.5t-127.032 86q-72.532 31.5-155 31.5ZM480-140q55 
                            0 107.5-16T691-212q-51-36-104-55t-107-19q-54 0-107 19t-104 55q51 40 103.5 
                            56T480-140Zm0-370q34 0 55.5-21.5T557-587q0-34-21.5-55.5T480-664q-34 0-55.5 
                            21.5T403-587q0 34 21.5 55.5T480-510Zm0-77Zm0 374Z"
                            style={{ fill: '#009688' }}/></svg>
                    </div>

                    <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <input type="text" name="userId" id="userId"
                                className="m-2 bg-white border border-gray-300 
                                    text-gray-900 sm:text-sm rounded-lg 
                                    focus:ring-green-600 
                                    focus:border-green-600 block w-full p-2.5 
                                    focus:outline-none"
                                onChange={handleChange}
                                placeholder="ユーザーID*" />

                            <input type="password" name="password" id="password"
                                placeholder="パスワード*"
                                className="m-2 bg-white border border-gray-300 
                                    text-gray-900 sm:text-sm rounded-lg 
                                    focus:ring-green-600 
                                    focus:border-green-600 block w-full p-2.5 
                                    focus:outline-none"
                                onChange={handleChange}
                            />
                        </div>
                        <button type="submit" className="w-full m-2
                   text-white bg-green-650 hover:bg-green-700 
                   focus:ring-4 focus:outline-none focus:ring-primary-300 
                   font-medium rounded-lg text-sm px-5 py-2.5 text-center">ログイン</button>

                        {message &&
                            <div className="m-2 w-full bg-red-100 border border-red-400 text-sm
                         text-red-700 px-2 py-1 rounded relative" role="alert">
                                <span className="block sm:inline">{message}</span>
                            </div>}
                    </form>
                </div>
            </div>
        </div>

        // <ThemeProvider theme={theme}>
        //     <Container component="main" maxWidth="xs">
        //         <CssBaseline />
        //         <Card
        //             sx={{
        //                 mt: 8,
        //                 display: 'flex',
        //                 flexDirection: 'column',
        //                 alignItems: 'center',
        //                 bgcolor: '#FAFFF9'
        //             }}
        //         >

        //             <Typography component="h1" variant="h5"
        //                 sx={{ mt: 2 }}>
        //                 fカルテット 顧客管理
        //             </Typography>

        //             <Avatar sx={{ mt: 2, bgcolor: 'primary.main' }}>
        //                 <AccountBoxIcon />
        //             </Avatar>

        //             <Box component="form" onSubmit={handleSubmit}
        //                 sx={{ mt: 0, p: 1}}>
        //                 <TextField sx={{bgcolor: 'white'}}
        //                     margin="normal"
        //                     required
        //                     fullWidth
        //                     id="userId"
        //                     name="userId"
        //                     label="ユーザーID"
        //                     autoComplete="userId"
        //                     autoFocus
        //                     onChange={handleChange}
        //                 />
        //                 <TextField sx={{bgcolor: 'white'}}
        //                     margin="normal"
        //                     required
        //                     fullWidth
        //                     name="password"
        //                     label="パスワード"
        //                     type="password"
        //                     id="password"
        //                     autoComplete="current-password"
        //                     onChange={handleChange}
        //                 />
        //                 <Button
        //                     type="submit"
        //                     variant="contained"
        //                     fullWidth
        //                     sx={{ padding:1, mt: 1, mb: 1 }}
        //                 >ログイン</Button>
        //             </Box>
        //         </Card>
        //         {message && <Alert severity="error" sx={{ mt: 1 }}>{message}</Alert>}
        //     </Container>
        // </ThemeProvider>
    );
};

// const loginApi = async (userid, password) => {
//     const params =
//     {
//         method: "POST",
//         headers: {
//             'Accept': 'application/json, text/plain',
//             'Content-Type': 'application/json;charset=UTF-8'
//         },
//         body: JSON.stringify({ userId: userid, password: password })
//     };
//     const response = await fetch('api', params);
//     const data = await response.json();
//     return data;
// };