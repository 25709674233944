import react, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../components/AuthContextProvider';
import { MainTemplate2 } from '../../templates/MainTemplate2';
import {
    Button, Box, TableContainer, Table, TableRow,
    TableHead, TableBody, TextField, Paper, Typography, InputLabel, Stack, Select
} from "@mui/material";
import { AirlineSeatIndividualSuiteSharp, Create, Label, SignalCellularConnectedNoInternet4BarRounded } from '@mui/icons-material';
//import { FetchData } from '../FetchUtility';
import { FetchPost } from '../../utils/FetchUtility';
import { Title, FLabel } from '../../components/Title';
import { DataGrid, GridColDef, GridToolbar, jaJP } from '@mui/x-data-grid';
import { useParams } from "react-router-dom";
import { useNavigate, Link, Navigate, useLocation } from "react-router-dom";
import { AlertMailSendType, MedicalFacilityItem, MedicalFacilityRequest, MyComponentProps, RoleType } from '../../types/TypeDefinition';
import { LinkButton } from '../../components/FLink';
import * as Routes from "../../ApiRoute";
import axios from "axios";
import { FTable } from '../../components/FTable';
import { FTableBody } from '../../components/FTableBody';
import { FTableRow } from '../../components/FTableRow';
import { TableCell } from '../../components/TableCell';
import { TableCellHead } from "../../components/TableCellHead";
import { FInput } from '../../components/FInput';
import { InputDialog } from '../../components/InputDialog';
import { ButtonType, FButton } from '../../components/FButton';
import { AccountRequest } from '../../types/TypeDefinition';
import { Account } from '../../ApiRoute';
import { AccountItemToRequest } from '../../functions/Account';
import { getValue } from '@testing-library/user-event/dist/utils';

export const AccountEdit: React.FC<MyComponentProps> = () => {
    const { loginItem } = useAuth();
    const [item, setItem] = useState<AccountRequest>({ accountId: 0, userId: "", userName: "" });
    const [value, setValue] = useState();
    const { accountId } = useParams();
    const navigate = useNavigate();
    const [inputError, setInputError] = useState(false);
    const inputRef = useRef(null);
    const location = useLocation();
    const [message, setMessage] = useState("");
    const [isAuthError, setIsAuthError] = useState(false);

    const alertMailSendTypes = [
        { FLabel: '送信しない', value: "0" },
        { FLabel: '送信する', value: "1" }
    ]

    //const alertMailSendTypes = getAlertMailSendTypeList();

    useEffect(() => {
        const f = async () => {
            try {
                const response = await axios.get(`${Routes.Account.GetById}/${accountId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${loginItem.token}`,
                        }
                    });
                setItem(AccountItemToRequest(response.data, loginItem.account.code));
            }
            catch (error: any) {
                switch (error.response?.status) {
                    case 401:
                        setIsAuthError(true);
                        break;
                    default:
                        break;
                }
            };
        };
        f();
    }, []);

    const handleSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        let tempMessage = "";

        setMessage("");

        if (!item) {
            return;
        }

        try {
            await axios.post(Routes.Account.Register,
                item, {
                headers: {
                    Authorization: `Bearer ${loginItem.token}`,
                }
            });
        }
        catch (error: any) {
            tempMessage = "登録処理でエラーが発生しました。";
            setMessage(tempMessage)
            switch (error.response?.status) {
                case 401:
                    setIsAuthError(true);
                    break;
                default:
                    break;
            }
        };

        // 元のページに戻る
        if (!tempMessage) {
            navigate(-1);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement |
        HTMLSelectElement>) => {
        if (!item) {
            return;
        }

        setItem({
            ...item,
            [e.target.name]: e.target.value
        })
    };

    const NavigateLogin = () => {
        const origin = (location.state as any)?.from?.pathname || '/login';
        return <Navigate to={origin} />;
    }

    return (
        isAuthError ? NavigateLogin() :
            <MainTemplate2>
                <div>
                    <Title className='ml-2'>ユーザー 編集</Title>

                    <div className="m-2 border w-half shadow-md sm:rounded-lg border-blue-300">
                        <div className="mt-2 flex items-center">
                            <FLabel className="w-32">ユーザーID</FLabel>
                            {
                                accountId !== "0" ? (
                                    <FLabel
                                        className="w-32 border rounded p-1 bg-gray-100" >
                                        {item?.userId}
                                    </FLabel>
                                ) : (
                                    <FInput className='w-32'
                                        name='userId'
                                        value={item?.userId}
                                        onChange={handleChange} ></FInput>
                                )
                            }
                        </div>

                        <div className="mt-2 flex items-center">
                            <FLabel className="w-32">ユーザー名</FLabel>
                            <FInput className='w-64'
                                name='userName'
                                value={item?.userName}
                                onChange={handleChange} ></FInput>
                        </div>

                        {loginItem.roleId === RoleType.Admin &&
                            <div className="mt-2 flex items-center">
                                <FLabel className="w-32">権限</FLabel>
                                <FInput className='w-16'
                                    name='userRoleId'
                                    value={item?.userRoleId?.toString() ?? ""}
                                    onChange={handleChange} ></FInput>
                                <FLabel>(1:一般 8:監視プログラム用 9:管理者)</FLabel>
                                <FLabel>{loginItem.roleId}</FLabel>
                            </div>
                        }

                        <div className="mt-2 mb-2 flex items-center">
                            <FLabel className="w-32">メールアドレス</FLabel>
                            <FInput className="w-72"
                                name='emailAddress'
                                value={item?.emailAddress}
                                onChange={handleChange} ></FInput>
                        </div>

                        <div className="mt-2 mb-2 flex items-center">
                            <FLabel className="w-32">アラートメール送信区分</FLabel>
                            <select className="w-72 form-select"
                                name='alertMailSendType'
                                value={item?.alertMailSendType?.toString() || ""}
                                onChange={handleChange}>

                                {alertMailSendTypes.map(type => (
                                    <option key={type.value} value={type.value.toString()}>
                                        {type.FLabel}</option>
                                ))}

                            </select>
                        </div>

                    </div>

                    <FButton className="m-2" buttonType={ButtonType.Cancel}
                        onClick={() => navigate(-1)}>キャンセル</FButton>
                    <FButton className="mt-2" onClick={handleSave}>保存</FButton>

                    {message &&
                        <div className="m-2 bg-red-100 border border-red-400 text-sm
                         text-red-700 px-2 py-1 rounded relative" role="alert">
                            <span className="block sm:inline">{message}</span>
                        </div>}
                </div>
            </MainTemplate2>
    );
};
