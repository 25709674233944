import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useAuth } from "./AuthContextProvider";
import { DecodeData } from "../utils/Base64Utility";
import { MyComponentProps } from '../types/TypeDefinition';

export const ProtectedRoute: React.FC<MyComponentProps> = ({ children }) => {
    const { loginItem } = useAuth();
    const location = useLocation();

    if (!loginItem || !loginItem.token) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    // トークンの有効期限確認
    const payload = DecodeData(loginItem.token.split('.')[1]);
    //console.log("payload:" + payload.nbf);

    const now = Date.now() / 1000;
    if (payload.exp < now) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return (
     <>
            {children ? children : <Outlet />}
     </>);
};
