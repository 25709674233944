import React, { useState } from 'react';

interface ConfirmProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  children: React.ReactNode;
}

export const ConfirmDialog: React.FC<ConfirmProps> = ({ isOpen, onClose, onDelete, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={onClose}></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
        {children}
        <div className="mt-4 flex">
    <button onClick={onDelete} className="px-4 py-2 mr-2 bg-red-600 text-white rounded hover:bg-red-700">
      削除
    </button>
    <button onClick={onClose} className="px-4 py-2 ml-0 bg-gray-300 text-black rounded hover:bg-gray-400">
      キャンセル
    </button>
  </div>
        </div>
      </div>
    </div>


  );
};
