import { MedicalFacilityItem, MedicalFacilityRequest, MonitoringStatus} from "../types/TypeDefinition";

export function MedicalFacilityItemToRequest(item: MedicalFacilityItem, accountId: number) {
    const request: MedicalFacilityRequest = {
        medicalId: item.medicalInstitution.code,
        name: item.medicalInstitution.text,
        formalName : item.formalName,
        postalCode : item.postalCode,
        address : item.address,
        tel : item.tel,
        fax : item.fax,
        eMailAddress : item.eMailAddress,
        homepageUrl: item.homepageUrl,
        representative: item.representative,
        staff1 : item.staff1,
        staff2 : item.staff2,
        note : item.note,
        monitoringStatus: item.monitoringStatus,
        accountId : accountId
    };
    return request;
}

export function GetMonitoringStatusText(monitoringStatus?: number) {

    if (!monitoringStatus) {
        return "";
    }

    switch (monitoringStatus) {
        case MonitoringStatus.未設定:
            return "未設定"
        case MonitoringStatus.監視対象:
            return "監視対象"
        case MonitoringStatus.監視対象外:
            return "監視対象外"
    }
}

