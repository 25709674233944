import React from "react";
import { FComponentProps } from "./FComponentProps";

export const TableCell: React.FC<FComponentProps> = ({ className, children }) => {

    return (
        <td className={`px-2 ${className}`}>
            {children}
        </td>
    )
};
