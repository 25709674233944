import React from "react";
import { FComponentProps } from "./FComponentProps";

export const FTableRow: React.FC<FComponentProps> = ({ className, children }) => {

    return (
        <tr className={`${className}`}>
            {children}
        </tr>
    )
};
