
export type MyComponentProps = {
    children?: React.ReactNode;
};

export type CodedElement<T> = {
    code: T,
    text: string
}


export type MedicalFacilityRequest = {
    medicalId: string,
    name: string,
    formalName?: string,
    postalCode?: string,
    address?: string,
    tel?: string,
    fax?: string,
    eMailAddress?: string,
    homepageUrl?: string,
    representative?: string,
    staff1?: string,
    staff2?: string,
    note?: string,
    monitoringStatus?: number,
    accountId?: number
}

export enum MachineType {
    MainServer = 1,
    SubServer = 2,
    Client = 3
}

export enum AlertMailSendType {
    None =0,
    Send =1
}

export enum MonitoringStatus {
    未設定 = 0,
    監視対象 = 1,
    監視対象外 = 2
}

export enum RoleType {
    Normal = 1,
    MonitoringApp = 8,
    Admin = 9
}

export enum SettingKeyType {
    Default = 1,
    Common = 2,
    Hospital = 3,
    Machine = 4
}

export enum SectionId {
    Basic = 1,
    FkartetDb = 2,
    Local = 3,
    AlertCondition = 4,
    RemoteBackup = 5
}

export enum FkartetDbItemId {
    /// PatientDBサーバー名
    PatientDbServerName = 1,
    /// PatientDB名
    PatientDbName = 2,
    /// PatientDB認証方式
    PatientDbConnectType = 3,
    /// PatientDBユーザ名
    PatientDbUserName = 4,
    /// PatientDBパスワード
    PatientDbPassword = 5,
    /// PatientDBタイムアウト(秒)
    PatientDbTimeout = 6,
    /// WorkDBサーバー名
    WorkDbServerName = 7,
    /// WorkDB名
    WorkDbName = 8,
    /// WorkDB認証方式
    WorkDbConnectType = 9,
    /// WorkDBユーザ名
    WorkDbUserName = 10,
    /// WorkDBパスワード
    WorkDbPassword = 11,
    /// WorkDBタイムアウト(秒)
    WorkDbTimeout = 12,
    /// HistDBサーバー名
    HistDbServerName = 13,
    /// HistDB名
    HistDbName = 14,
    /// HistDB認証方式
    HistDbConnectType = 15,
    /// HistDBユーザ名
    HistDbUserName = 16,
    /// HistDBパスワード
    HistDbPassword = 17,
    /// HistDBタイムアウト(秒)
    HistDbTimeout = 18
}

export enum ItemLocalId {
    /// 監視プログラムダウンロードフォルダ
    MonitorProgramDownloadFolder = 1,
    /// アップデートプログラムのフォルダパス
    UpdateProgramFolder = 2,
    /// 最終イベントログ収集日時
    LastEventLogDate = 3,
    /// 更新確認ファイルのパス1
    UpdateConfirmFilePath1 = 4,
    /// 更新確認ファイルのパス2
    UpdateConfirmFilePath2 = 5,
    /// 更新確認ファイルのパス3
    UpdateConfirmFilePath3 = 6,
    /// 更新確認フォルダのパス1
    UpdateConfirmFolderPath1 = 7,
    /// 更新確認フォルダのパス2
    UpdateConfirmFolderPath2 = 8,
    /// 更新確認フォルダのパス3
    UpdateConfirmFolderPath3 = 9,
    /// 連携プログラムのフォルダパス1
    CooperationProgramFolderPath1 = 10,
    /// 連携プログラムのフォルダパス2
    CooperationProgramFolderPath2 = 11
}

export enum ItemRemoteBackupId {
    /// リモートバックアップ機能を使用するか
    UseRemoteBackup = 1,
    /// バックアップUploadプログラムのフォルダパス
    BackupUploadProgramFolder = 2,
    /// バックアップファイル取得フォルダ1
    BackupSourceFolder1 = 3,
    /// バックアップファイル取得フォルダ2
    BackupSourceFolder2 = 4,
    /// バックアップファイル取得フォルダ3
    BackupSourceFolder3 = 5,
    /// バックアップファイル取得フォルダ4
    BackupSourceFolder4 = 6,
    /// バックアップファイル取得フォルダ5
    BackupSourceFolder5 = 7
}

export type MedicalFacilityItem = {
    medicalInstitution: CodedElement<string>,
    formalName?: string,
    postalCode?: string,
    address?: string,
    tel?: string,
    fax?: string,
    eMailAddress?: string,
    homepageUrl?: string,
    representative?: string,
    staff1?: string,
    staff2?: string,
    note?: string,
    monitoringStatus?: MonitoringStatus,
    insertUser: CodedElement<number>,
    updateUser: CodedElement<number>
}

export type MachineInfoItem = {
    machineId: string,
    medicalInstitution: CodedElement<string>,
    pcName: string,
    ipAddress?: string,
    domainName?: string,
    machineType?: number
    location?: string,
    modelName?: string,
    cpu?: string
    memory?: number,
    storageType?: number,
    storage?: number,
    diskDrive?: string,
    oS_InstallDate?: Date,
    oS_Name?: string,
    oS_Version?: string,
    oS_BuildNumber?: string,
    oS_SP_Version?: string,
    oS_SerialNumber?: string,
    monitorSize?: string,
    remoteSoftware?: string,
    mSOfficeVersion?: string,
    sqlServerVersion?: string,
    sqlRecoveryModel?: string,
    dotNetVersion?: string,
    identifyingNumber?: string,
    supportNumber?: string,
    supportType?: string,
    supportExpirationDate?: Date,
    purchaseDate?: Date,
    purchaser?: string,
    licenseKey?: string,
    lastUsedDate?: Date,
    note?: string,
    monitoringFlag: boolean,
    deleteFlag: boolean,
    insertDate?: Date,
    insertUser?: CodedElement<number>,
    updateDate?: Date,
    updateUser?: CodedElement<number>,
}

export type SettingItem = {
    keyType: number,
    keyValue: string,
    section: CodedElement<number>,
    item: CodedElement<number>,
    itemValue?: string,
}

export type SettingRequest = {
    keyType: number,
    keyValue: string,
    sectionId: number,
    itemId: number,
    itemValue: string,
    accountId: number
}

export type AccountRequest = {
    accountId: number,
    userId: string,
    userName: string,
    password?: string,
    userRoleId?: number,
    emailAddress?: string,
    alertMailSendType?: number,
    updaterId?: number
}

export type BackupStorageElement = {
    path: string,
    fileName: string,
    length?: number,
    lengthGbText?: string,
    lengthKbText?: string,
    lastModified?: Date
}

export type BackupStorageElementEntry = {
    folderName: string,
    elements: Array<BackupStorageElement>
}

export type BackupStorageItem = {
    medicalInstitution: CodedElement<string>,
    entries: Array<BackupStorageElementEntry>,
    status: number,
    statusText: string
}

export type MonitorProgramSetting = {
    monitoringProgramVersion: string,
    updateProgramVersion: string,
    monitorProgramStorageContainerName: string,
    monitorProgramStorageConnectionString: string,
    backupUploadProgramVersion: string,
    backupProgramStorageContainerName: string,
    programDownloadFolder: string,
    updateProgramFolder: string,
    lastEventLogDate: Date,
    modifyCheckFile1Path: string,
    modifyCheckFile2Path: string,
    modifyCheckFile3Path: string,
    modifyCheckFolder1Path: string,
    modifyCheckFolder2Path: string,
    modifyCheckFolder3Path: string,
    cooperativeProgramFolder1: string,
    cooperativeProgramFolder2: string,
    criticalEventIds: Array<number>[],
    warningEventIds: Array<number>[],
    monitoringDateLimitHours: number,
    bootUpTimeLimitDays: number,
    criticalStorageFreeSize: number,
    warningStorageFreeSize: number,
    criticalEventWords?: Array<string>,
    warningEventWords?: Array<string>,
    limitIdentityNumber: number,
    modifyCheckFile1LimitMinites: number,
    modifyCheckFile2LimitMinites: number,
    modifyCheckFile3LimitMinites: number,
    modifyCheckFolder1LimitMinites: number,
    modifyCheckFolder2LimitMinites: number,
    modifyCheckFolder3LimitMinites: number,
    warningWorkDbRecordCount: number,
    useRemoteBackup: boolean,
    backupUploadProgramFolder: string,
    backupSourceFolder1: string,
    backupSourceFolder2: string,
    backupSourceFolder3: string,
    backupSourceFolder4: string,
    backupSourceFolder5: string
}

export type MySetting = {
    medicalId: string,
    machineId: string,
    basic: ItemBasic,
    fkartetDb: ItemFkartetDb,
    local: ItemLocal,
    alertCondition: ItemAlertCondition,
    remoteBackup: ItemRemoteBackup
}

export type ItemBasic = {
    monitorProgramVersion: string,
    updateProgramVersion: string,
    backupUploadProgramVersion: string,
    backupAzureStorageContainerName: string,
}

export type ItemFkartetDb = {
    patientDbServerName: string,
    patientDbName: string,
    patientDbConnectType: number,
    patientDbUserName: string,
    patientDbPassword: string,
    patientDbTimeout: number,
    patientDbSetting: DbSetting,
    workDbServerName: string,
    workDbName: string,
    workDbConnectType: number,
    workDbUserName: string,
    workDbPassword: string,
    workDbTimeout: number,
    workDbSetting: DbSetting,
    histDbServerName: string,
    histDbName: string,
    histDbConnectType: number,
    histDbUserName: string,
    histDbPassword: string,
    histDbTimeout: number,
    histDbSetting: DbSetting,
    sectionId: number
}

export type DbSetting = {
    connectionString: string,
    dbServerName: string,
    dbName: string,
    dbConnectType: number,
    dbUserName: string,
    dbPassword: string,
    timeout: number
}

export type ItemLocal = {
    monitorProgramDownloadFolder: string,
    updateProgramFolder: string,
    lastEventLogDate: Date,
    updateConfirmFilePath1: string,
    updateConfirmFilePath2: string,
    updateConfirmFilePath3: string,
    updateConfirmFolderPath1: string,
    updateConfirmFolderPath2: string,
    updateConfirmFolderPath3: string,
    cooperationProgramFolderPath1: string,
    cooperationProgramFolderPath2: string,
    sectionId: number
}

export type ItemAlertCondition = {
    criticalEventIds: Array<number>,
    warningEventIds: Array<number>,
    elapsedHoursLastMonitor: number,
    elapsedDaysLastBootUp: number,
    criticalFreeDiskSpaceRatio: number,
    warningFreeDiskSpaceRatio: number,
    elapsedMinutesUpdateCheckFile1: number,
    criticalIdentityNumber: number,
    criticalEventWords: Array<string>,
    warningEventWords: Array<string>,
    elapsedMinutesUpdateCheckFile2: number,
    elapsedMinutesUpdateCheckFile3: number,
    elapsedMinutesUpdateCheckFolder1: number,
    elapsedMinutesUpdateCheckFolder2: number,
    elapsedMinutesUpdateCheckFolder3: number,
    warningWorkDbRecordCount: number,
    sectionId: number
}

export type ItemRemoteBackup = {
    useRemoteBackup: boolean,
    backupUploadProgramFolder: string,
    backupSourceFolder1: string,
    backupSourceFolder2: string,
    backupSourceFolder3: string,
    backupSourceFolder4: string,
    backupSourceFolder5: string,
    sectionId: number
}

export type ItemEnvironment = {
    mainServerName: string,
    subServerName: string
}

export type AlertItem = {
    instanceId: string,
    alertInfoId: number,
    machineId?: string,
    medicalInstitution: CodedElement<string>,
    alertName?: string,
    alertLevel: CodedElement<number>,
    monitoringResult?: string,
    monitoringDate?: Date,
    alertMessage?: string,
    checkUser?: CodedElement<number>,
    checkDate?: Date,
    monitoringResultId?: number,
    eventLogId?: number
}

export type DataElement = {
    key: string,
    title?: string,
    value?: string,
    linkType?: string,
    linkKey?: string
}

export type DriveRecord = {
    name?: string,
    size?: number,
    freeSpace?: number
}

export type MonitoringResultItem = {
    machineInfo: MachineInfoItem,
    monitoringResultId?: number,
    monitoringDate?: Date,
    oS_BootUpTime?: Date,
    freeMemory?: number,
    virtualMemory?: number,
    freeVirtualMemory?: number,
    driveRecords: Array<DriveRecord>,
    patientDbSize?: number,
    workDbSize?: number,
    workDbRecordCount?: number,
    historyDbSize?: number,
    file1ModifyDate?: Date,
    file2ModifyDate?: Date,
    file3ModifyDate?: Date,
    folder1ModifyDate?: Date,
    folder2ModifyDate?: Date,
    folder3ModifyDate?: Date,
    eventLogId?: number
}

export type FkartetSettingItem = {
    key: string,
    medicalInstitution: CodedElement<string>,
    sectionId: number,
    itemId: number,
    itemValue?: string
}

export type AccountItem = {
    account: CodedElement<number>,
    userId: string,
    password: string,
    lastPassword?: string,
    previousPassword?: string,
    passwordChangeDate?: Date,
    userRoleId?: number,
    emailAddress?: string,
    alertMailSendType?: AlertMailSendType,
    deleteFlag: boolean,
    displayIndex?: number
}

export type MonitorProgramSettingTabStringRequest = {
    tabStringBase64: string,
    hasHeader: boolean,
    accountId?: number
}

// 見出しと値を格納するための入れ物
export type TitleAndValue = {
    title: string,
    value?: string
}
