import React from "react";
import { FComponentProps } from "./FComponentProps";

// interface FTableProps {
//     items?: any[];
//     children?: React.ReactNode;
// }

export const FTable: React.FC<FComponentProps> = ({ className, children }) => {

    return (
        <table className={`w-full text-sm text-left text-gray-500 ${className}`}>
            {children}
        </table>
    );
};
