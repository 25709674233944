
export const FetchData = async (url, token) => {
    const params =
    {
        method: "GET",
        headers: {
            'Authorization': 'Bearer ' + token,
        },
    };

    const response = await fetch(url, params);
    return await response.json();
};

export const FetchPost = async (url, token, params) => {
    const request =
    {
        method: "POST",
        headers: {
            'Accept': 'application/json, text/plain',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(params)
    };

    const response = await fetch(url, request);

    return await response.json();
};

export const FetchDelete = async (url, token, params) => {
    const request =
    {
        method: "DELETE",
        headers: {
            'Accept': 'application/json, text/plain',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(params)
    };

    const response = await fetch(url, request);

    return await response.json();
};
