// 日付をYYYY-MM-DDの書式で返すメソッド
export default function formatDate(baseDate?:Date) {

    //{console.log(baseDate)};

    if (!baseDate) {
        return '';
    }

    const dt = new Date(baseDate);

    var y = dt.getFullYear();
    var m = ('00' + (dt.getMonth()+1)).slice(-2);
    var d = ('00' + dt.getDate()).slice(-2);
    var H = ('00' + dt.getHours()).slice(-2);
    var min = ('00' + dt.getMinutes()).slice(-2);
    var sec = ('00' + dt.getSeconds()).slice(-2);

    //return (y + '/' + m + '/' + d + ' ' + H + ':' + min + ':' + sec);
    return (y + '/' + m + '/' + d + ' ' + H + ':' + min);
  }
  