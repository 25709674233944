import React from "react";
import { FComponentProps } from "./FComponentProps";

export const FTableBody: React.FC<FComponentProps> = ({ className, children }) => {

    return (
        <tbody className={`relative shadow-md sm:rounded-lg ${className}`}>
            {children}
        </tbody>
    )
};
