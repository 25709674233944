import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Title } from '../../components/Title';
import { MainTemplate2 } from '../../templates/MainTemplate2';
import axios from "axios";
import { useAuth } from '../../components/AuthContextProvider';
import { Navigate, useLocation } from 'react-router-dom';
import '../../css/custom.css';
import { MySetting, MyComponentProps } from '../../types/TypeDefinition';
import * as Routes from "../../ApiRoute";

export const AlertHelp: React.FC<MyComponentProps> = () => {
    const { loginItem } = useAuth();
    const [item, setItem] = useState<MySetting>();
    const location = useLocation();
    const [isAuthError, setIsAuthError] = useState(false);

    useEffect(() => {
        const getPost = async () => {
            try {
                const response = await axios.get(Routes.Setting.GetMySetting, {
                    headers: {
                        Authorization: `Bearer ${loginItem.token}`,
                    },
                    params: {
                        medicalId:"\"\"",
                        machineId:"\"\""
                    }
                });
                setItem(response.data);
            }
            catch (error: any) {
                console.log(error.response.status);
                switch (error.response?.status) {
                    case 401:
                        setIsAuthError(true);
                        break;
                    default:
                        break
                }
            };
        }
        getPost();

    }, []);

    const NavigateLogin = () => {
        const origin = (location.state as any)?.from?.pathname || '/login';
        return <Navigate to={origin} />;
    }
    
    return (
        isAuthError ? NavigateLogin() :
            <MainTemplate2>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', width: '2000' }}>
                    <Title>アラート表示基準</Title>
                    {item && (
                        <>
                    <h2 className="mb-2 text-md text-red-600 dark:text-white">重大</h2>
                    
                    <ul className="space-y-1 list-disc list-inside text-gray-500 dark:text-gray-400">
                        <li>監視結果が <b className="setting-font-color">{item?.alertCondition.elapsedHoursLastMonitor}</b> 時間以内に存在しない</li>
                        <li>fカルテットDB内にオートナンバーが  <b className="setting-font-color">{item?.alertCondition.criticalIdentityNumber.toLocaleString()}</b> を超過したテーブルが存在</li>
                        <li>ディスクの空き容量が  <b className="setting-font-color">{item?.alertCondition.criticalFreeDiskSpaceRatio}</b> % 以下</li>
                        <li>更新確認ファイル1が <b className="setting-font-color">{item?.alertCondition.elapsedMinutesUpdateCheckFile1}</b> 分以上更新されていない</li>
                        <li>更新確認ファイル2が <b className="setting-font-color">{item?.alertCondition.elapsedMinutesUpdateCheckFile2}</b> 分以上更新されていない</li>
                        <li>更新確認ファイル3が <b className="setting-font-color">{item?.alertCondition.elapsedMinutesUpdateCheckFile3}</b> 分以上更新されていない</li>
                        <li>更新確認フォルダ1が <b className="setting-font-color">{item?.alertCondition.elapsedMinutesUpdateCheckFolder1}</b> 分以上更新されていない</li>
                        <li>更新確認フォルダ2が <b className="setting-font-color">{item?.alertCondition.elapsedMinutesUpdateCheckFolder2}</b> 分以上更新されていない</li>
                        <li>更新確認フォルダ3が <b className="setting-font-color">{item?.alertCondition.elapsedMinutesUpdateCheckFolder3}</b> 分以上更新されていない</li>
                        <li>最新の監視結果のイベントログIDに <b className="setting-font-color">{item?.alertCondition.criticalEventIds?.join(' or ')}</b> が存在</li>
                        <li>最新の監視結果のイベントログメッセージに <b className="setting-font-color">{item?.alertCondition.criticalEventWords?.join(' or ')}</b> が存在</li>
                    </ul>
                    <br />
                    <h2 className="mb-2 text-md text-orange-600 dark:text-white">注意</h2>
                    <ul className="space-y-1 list-disc list-inside text-gray-500 dark:text-gray-400">
                        <li><b className="setting-font-color">{item?.alertCondition.elapsedDaysLastBootUp}</b> 日以上再起動していない</li>
                        <li>ディスクの空き容量が <b className="setting-font-color">{item?.alertCondition.warningFreeDiskSpaceRatio}</b> % 以下</li>
                        <li>WorkDBの合計レコード件数が <b className="setting-font-color">{item?.alertCondition.warningWorkDbRecordCount.toLocaleString()}</b> 件以上 (M_InfoTableを除く)</li>
                        <li>最新の監視結果のイベントログIDに <b className="setting-font-color">{item?.alertCondition.warningEventIds?.join(' or ')}</b> が存在</li>
                        <li>最新の監視結果のイベントログメッセージに <b className="setting-font-color">{item?.alertCondition.warningEventWords?.join(' or ')}</b> が存在</li>
                    </ul>
                    </>)}
                </Paper>
            </MainTemplate2>
    );
};

