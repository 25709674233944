import react, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../components/AuthContextProvider';
import { MainTemplate2 } from '../../templates/MainTemplate2';
import {
    Button, Box, TableContainer, Table, TableRow,
    TableHead, TableBody, TextField, Paper, Typography, InputLabel, Stack
} from "@mui/material";
import { AirlineSeatIndividualSuiteSharp, Create, Label, SignalCellularConnectedNoInternet4BarRounded } from '@mui/icons-material';
//import { FetchData } from '../FetchUtility';
import { FetchPost } from '../../utils/FetchUtility';
import { Title, FLabel } from '../../components/Title';
import { DataGrid, GridColDef, GridToolbar, jaJP } from '@mui/x-data-grid';
import { useParams } from "react-router-dom";
import { useNavigate, Link, Navigate, useLocation } from "react-router-dom";
import { MedicalFacilityItem, MedicalFacilityRequest, MyComponentProps } from '../../types/TypeDefinition';
import { LinkButton } from '../../components/FLink';
import * as Routes from "../../ApiRoute";
import axios from "axios";
import { MedicalFacilityItemToRequest } from '../../functions/MedicalFacility';
import { FTable } from '../../components/FTable';
import { FTableBody } from '../../components/FTableBody';
import { FTableRow } from '../../components/FTableRow';
import { TableCell } from '../../components/TableCell';
import { TableCellHead } from "../../components/TableCellHead";
import { FInput } from '../../components/FInput';
import { InputDialog } from '../../components/InputDialog';
import { ButtonType, FButton } from '../../components/FButton';


export const MedicalFacilityEdit: React.FC<MyComponentProps> = () => {
    const { loginItem } = useAuth();
    const { medicalId } = useParams();
    const [item, setItem] = useState<MedicalFacilityRequest>({ medicalId: "", name: "" });
    const [value, setValue] = useState();
    const { key } = useParams();
    const navigate = useNavigate();
    const [inputError, setInputError] = useState(false);
    const inputRef = useRef(null);
    const location = useLocation();
    const [isAuthError, setIsAuthError] = useState(false);


    useEffect(() => {
        if (!medicalId) {
            return;
        }

        const f = async () => {
            try {
                const response = await axios.get(`${Routes.MedicalFacility.GetById}/${medicalId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${loginItem.token}`,
                        }
                    });
                setItem(MedicalFacilityItemToRequest(response.data, loginItem.account.code));
            }
            catch (error: any) {
                console.log(error.response);
                switch (error.response?.status) {
                    case 401:
                        setIsAuthError(true);
                        break;
                    default:
                        break;
                }
            };
        };
        f();
    }, []);

    const handleSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (!item) {
            return;
        }

        await axios.post(Routes.MedicalFacility.Register,
            item, {
            headers: {
                Authorization: `Bearer ${loginItem.token}`,
            }
        });


        // 元のページに戻る
        navigate(-1);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!item) {
            return;
        }

        setItem({
            ...item,
            [e.target.name]: e.target.value
        })
    };

    const NavigateLogin = () => {
        const origin = (location.state as any)?.from?.pathname || '/login';
        return <Navigate to={origin} />;
    }

    return (
        isAuthError ? NavigateLogin() :
            <MainTemplate2>
                <div>
                    <Title>医療機関 編集</Title>

                    <div className="m-2 border w-half shadow-md sm:rounded-lg border-blue-300">
                        <div className="mt-2">
                            <FLabel className="w-32">医療機関ID</FLabel>
                            <FLabel
                                className="border rounded p-1 bg-gray-100" >
                                {item?.medicalId}
                            </FLabel>
                        </div>

                        <div className="mt-2">
                            <FLabel className="w-32">医療機関名</FLabel>
                            <FInput
                                name='name'
                                value={item?.name}
                                onChange={handleChange} ></FInput>
                        </div>

                        <div className="mt-2">
                            <FLabel className="w-32">正式名称</FLabel>
                            <FInput
                                name='formalName'
                                value={item?.formalName}
                                onChange={handleChange} ></FInput>
                        </div>


                        {/* <div>
                                <Typography variant='body1' sx={{ mt: 1, width: 130 }}>医療機関ID</Typography>
                                <TextField sx={{ bgcolor: 'white', ml: 1, width: 70 }}
                                    size="small"
                                    id='medicalId'
                                    name='medicalId'
                                    value={item?.medicalId}
                                    onChange={handleChange}
                                    error={inputError}
                                    inputProps={{ maxLength: 4, pattern: "^[a-zA-Z0-9_]+$" }}
                                    inputRef={inputRef}
                                //helperText={inputRef?.current?.validationMessage}
                                />
                            </div> */}

                        {/* <Stack direction="row" sx={{ mt: 1 }}>
                                <Typography variant='body1' sx={{ mt: 1, width: 130 }}>医療機関名</Typography>
                                <TextField sx={{ bgcolor: 'white', ml: 1, width: 400 }}
                                    size="small"
                                    id='name'
                                    name='name'
                                    value={item?.name}
                                    onChange={handleChange}
                                    error={inputError}
                                    inputProps={{ maxLength: 32 }}
                                    inputRef={inputRef}
                                //helperText={inputRef?.current?.validationMessage}
                                />
                            </Stack> */}

                        <Stack direction="row" sx={{ mt: 1 }}>
                            <Typography variant='body1' sx={{ mt: 1, width: 130 }}>正式名称</Typography>
                            <TextField sx={{ bgcolor: 'white', ml: 1, width: 400 }}
                                size="small"
                                id='formalName'
                                name='formalName'
                                value={item?.formalName}
                                onChange={handleChange}
                                error={inputError}
                                inputProps={{ maxLength: 64 }}
                                inputRef={inputRef}
                            //helperText={inputRef?.current?.validationMessage}
                            />
                        </Stack>

                        <Stack direction="row" sx={{ mt: 1 }}>
                            <Typography variant='body1' sx={{ mt: 1, width: 130 }}>郵便番号</Typography>
                            <TextField sx={{ bgcolor: 'white', ml: 1, width: 100 }}
                                size="small"
                                id='postalCode'
                                name='postalCode'
                                value={item?.postalCode}
                                onChange={handleChange}
                                error={inputError}
                                inputProps={{ maxLength: 8, pattern: "/^\d{3}-\d{4}$/" }}
                                inputRef={inputRef}
                            //helperText={inputRef?.current?.validationMessage}
                            />

                        </Stack>

                        <Stack direction="row" sx={{ mt: 1 }}>
                            <Typography variant='body1' sx={{ mt: 1, width: 130 }}>住所</Typography>
                            <TextField sx={{ bgcolor: 'white', ml: 1, width: 500 }}
                                size="small"
                                multiline
                                maxRows={2}
                                id='address'
                                name='address'
                                value={item?.address}
                                onChange={handleChange}
                                inputProps={{ maxLength: 128 }}
                                inputRef={inputRef}
                            //helperText={inputRef?.current?.validationMessage}
                            />
                        </Stack>

                        <Stack direction="row" sx={{ mt: 1 }}>
                            <Typography variant='body1' sx={{ mt: 1, width: 130 }}>TEL</Typography>
                            <TextField sx={{ bgcolor: 'white', ml: 1, width: 180 }}
                                size="small"
                                id='tel'
                                name='tel'
                                value={item?.tel}
                                onChange={handleChange}
                                inputProps={{ maxLength: 20, pattern: "/^0\d{2,3}-\d{1,4}-\d{4}$/" }}
                                inputRef={inputRef}
                            //helperText={inputRef?.current?.validationMessage}
                            />
                        </Stack>

                        <Stack direction="row" sx={{ mt: 1 }}>
                            <Typography variant='body1' sx={{ mt: 1, width: 130 }}>FAX</Typography>
                            <TextField sx={{ bgcolor: 'white', ml: 1, width: 180 }}
                                size="small"
                                id='fax'
                                name='fax'
                                value={item?.fax}
                                onChange={handleChange}
                                inputProps={{ maxLength: 20, pattern: "/^0\d{2,3}-\d{1,4}-\d{4}$/" }}
                                inputRef={inputRef}
                            //helperText={inputRef?.current?.validationMessage}
                            />

                        </Stack>
                        <Stack direction="row" sx={{ mt: 1 }}>
                            <Typography variant='body1' sx={{ mt: 1, width: 130 }}>メールアドレス</Typography>
                            <TextField sx={{ bgcolor: 'white', ml: 1, width: 300 }}
                                size="small"
                                id='emailAddress'
                                name='emailAddress'
                                value={item?.eMailAddress}
                                onChange={handleChange}
                                inputProps={{ maxLength: 128, pattern: "^[a-zA-Z0-9-_\.]+@[a-zA-Z0-9-_\.]+$" }}
                                inputRef={inputRef}
                            //helperText={inputRef?.current?.validationMessage}
                            />
                        </Stack>

                        <Stack direction="row" sx={{ mt: 1 }}>
                            <Typography variant='body1' sx={{ mt: 1, width: 130 }}>ホームページURL</Typography>
                            <TextField sx={{ bgcolor: 'white', ml: 1, width: 600 }}
                                size="small"
                                id='homepageUrl'
                                name='homepageUrl'
                                value={item?.homepageUrl}
                                onChange={handleChange}
                                inputProps={{ maxLength: 256, pattern: "/^https?:\/\/" }}
                                inputRef={inputRef}
                            //helperText={inputRef?.current?.validationMessage}
                            />

                        </Stack>
                        <Stack direction="row" sx={{ mt: 1 }}>
                            <Typography variant='body1' sx={{ mt: 1, width: 130 }}>代表者</Typography>
                            <TextField sx={{ bgcolor: 'white', ml: 1, width: 220 }}
                                size="small"
                                id='representative'
                                name='representative'
                                value={item?.representative}
                                onChange={handleChange}
                                inputProps={{ maxLength: 32 }}
                                inputRef={inputRef}
                            //helperText={inputRef?.current?.validationMessage}
                            />

                        </Stack>
                        <Stack direction="row" sx={{ mt: 1 }}>
                            <Typography variant='body1' sx={{ mt: 1, width: 130 }}>担当者1</Typography>
                            <TextField sx={{ bgcolor: 'white', ml: 1, width: 220 }}
                                size="small"
                                id='staff1'
                                name='staff1'
                                value={item?.staff1}
                                onChange={handleChange}
                                inputProps={{ maxLength: 32 }}
                                inputRef={inputRef}
                            //helperText={inputRef?.current?.validationMessage}
                            />

                        </Stack>
                        <Stack direction="row" sx={{ mt: 1 }}>
                            <Typography variant='body1' sx={{ mt: 1, width: 130 }}>担当者2</Typography>
                            <TextField sx={{ bgcolor: 'white', ml: 1, width: 220 }}
                                size="small"
                                id='staff2'
                                name='staff2'
                                value={item?.staff2}
                                onChange={handleChange}
                                inputProps={{ maxLength: 32 }}
                                inputRef={inputRef}
                            //helperText={inputRef?.current?.validationMessage}
                            />

                        </Stack>
                        <Stack direction="row" sx={{ mt: 1 }}>
                            <Typography variant='body1' sx={{ mt: 1, width: 130 }}>備考</Typography>
                            <TextField sx={{ bgcolor: 'white', ml: 1, width: 500 }}
                                size="small"
                                id='note'
                                name='note'
                                value={item?.note}
                                onChange={handleChange}
                                inputProps={{ maxLength: 32 }}
                                inputRef={inputRef}
                            //helperText={inputRef?.current?.validationMessage}                                />
                            />
                        </Stack>

                        <div className="mt-2">
                            <FLabel className="w-32">監視状態</FLabel>
                            <FInput
                                name='monitoringStatus'
                                value={item?.monitoringStatus?.toString()}
                                onChange={handleChange} ></FInput>
                        </div>
                    </div>

                    <FButton className="mt-2" buttonType={ButtonType.Cancel}
                        onClick={() => navigate(-1)}>キャンセル</FButton>
                    <FButton className="mt-2" onClick={handleSave}>保存</FButton>
                </div>
            </MainTemplate2>
    );
};


