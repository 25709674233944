import React from "react";
import { useEffect, useState } from "react";
import { useParams, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from '../../components/AuthContextProvider';
import { MainTemplate2 } from '../../templates/MainTemplate2';
import Paper from '@mui/material/Paper';
import axios from "axios";
import { SettingsRemoteSharp } from "@mui/icons-material";
import { Button, Box, TableContainer, Table, TableRow, TableCell, TableBody, List } from "@mui/material";
import { FkartetDbItemId, ItemLocalId, ItemRemoteBackupId, MachineInfoItem, MedicalFacilityItem, MyComponentProps, MySetting, SectionId, SettingKeyType } from "../../types/TypeDefinition";
import * as Routes from "../../ApiRoute";
import { FTable } from "../../components/FTable";
import { FTableBody } from "../../components/FTableBody";
import { FTableRow } from "../../components/FTableRow";
import { TableCellHead } from "../../components/TableCellHead";
import { Title } from "../../components/Title";
import { FButton } from "../../components/FButton";
import { FLink } from "../../components/FLink";
import { GetKey, GetKeyFromItem } from "../../functions/Setting";

export const MedicalFacilitySetting: React.FC<MyComponentProps> = () => {
    const { loginItem } = useAuth();
    const { medicalId } = useParams();
    const [item, setItem] = useState<MedicalFacilityItem>();
    const [mySetting, setMySetting] = useState<MySetting>();
    const location = useLocation();
    const navigate = useNavigate();
    const [isAuthError, setIsAuthError] = useState(false);

    useEffect(() => {
        const f = async () => {
            try {
                const response = await axios.get(`${Routes.MedicalFacility.GetById}/${medicalId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${loginItem.token}`,
                        }
                    });
                setItem(response.data);
            }
            catch (error: any) {
                console.log(error.response);
                switch (error.response?.status) {
                    case 401:
                        setIsAuthError(true);
                        break;
                    default:
                        break;
                }
            };
        };
        f();
    }, []);

    useEffect(() => {
        const f = async () => {
            try {
                const url =`${Routes.MachineInfo.GetMainServer}/${medicalId}`;
                const machineResponse = await axios.get(url,
                    {
                        headers: {
                            Authorization: `Bearer ${loginItem.token}`,
                        }
                    });
                
                const machine: MachineInfoItem = machineResponse.data;

                const response = await axios.get(Routes.Setting.GetMySetting,
                    {
                        headers: {
                            Authorization: `Bearer ${loginItem.token}`,
                        },
                        params: {
                            medicalId: medicalId,
                            machineId: machine.machineId
                        }
                    });
                setMySetting(response.data);
            }
            catch (error: any) {
                console.log(error.response);
                switch (error.response?.status) {
                    case 401:
                        setIsAuthError(true);
                        break;
                    default:
                        break;
                }
            };
        };
        f();
    }, []);

    const NavigateLogin = () => {
        const origin = (location.state as any)?.from?.pathname || '/login';
        return <Navigate to={origin} />;
    }

    return (
        isAuthError ? NavigateLogin() :
            <MainTemplate2>
                   
                {item &&
                    <div>
                        <Title className="mt-4" >{item.medicalInstitution?.code} {item.medicalInstitution?.text} 設定</Title>
                        

                        {mySetting &&
                            <div>

                            <Title className="mt-4" >院内共通</Title>
                            <FTable>
                                <FTableBody>
                                    <FTableRow>
                                        <TableCellHead>患者DB</TableCellHead>
                                        <TableCell>{mySetting.fkartetDb.patientDbServerName}</TableCell>
                                        <TableCell>
                                        <FLink
                                             to={`../setting-detail/${GetKey(
                                                SettingKeyType.Hospital, mySetting.medicalId,
                                                SectionId.FkartetDb,
                                                FkartetDbItemId.PatientDbServerName)}`}>編集
                                            </FLink>
                                            </TableCell>                                    </FTableRow>

                                    <FTableRow>
                                        <TableCellHead>WorkDB</TableCellHead>
                                        <TableCell>{mySetting.fkartetDb.workDbServerName}</TableCell>
                                        <TableCell>
                                        <FLink
                                             to={`../setting-detail/${GetKey(
                                                SettingKeyType.Hospital, mySetting.medicalId,
                                                SectionId.FkartetDb,
                                                FkartetDbItemId.WorkDbServerName)}`}>編集
                                            </FLink>
                                            </TableCell>                                    </FTableRow>
                                    <FTableRow>
                                        <TableCellHead>履歴DB</TableCellHead>
                                        <TableCell>{mySetting.fkartetDb.histDbServerName}</TableCell>
                                        <TableCell>
                                        <FLink
                                             to={`../setting-detail/${GetKey(
                                                SettingKeyType.Hospital, mySetting.medicalId,
                                                SectionId.FkartetDb,
                                                FkartetDbItemId.HistDbServerName)}`}>編集
                                            </FLink>
                                            </TableCell>                                    </FTableRow>

                                    <FTableRow>
                                        <TableCellHead>リモートバックアップ機能</TableCellHead>
                                        <TableCell>{mySetting.remoteBackup.useRemoteBackup ? "使用する":"使用しない"}</TableCell>
                                        <TableCell>
                                        <FLink
                                             to={`../setting-detail/${GetKey(
                                                SettingKeyType.Hospital, mySetting.medicalId,
                                                SectionId.RemoteBackup,
                                                ItemRemoteBackupId.UseRemoteBackup)}`}>編集
                                            </FLink>
                                            </TableCell>                                    </FTableRow>
                                </FTableBody>
                            </FTable>

                            {!mySetting.machineId ? 
                            <div className="mt-4 text-red-500">主サーバーが登録されていません。</div>:
                            <>
                            <Title className="mt-4" >主サーバー {mySetting.machineId}</Title>
                            <FTable className="mb-2">
                                <FTableBody>
                                    <FTableRow>
                                        <TableCellHead>更新確認ファイル1</TableCellHead>
                                        <TableCell>{mySetting.local.updateConfirmFilePath1}</TableCell>
                                        <TableCell>
                                            <FLink
                                             to={`../setting-detail/${GetKey(
                                                SettingKeyType.Machine, mySetting.machineId,
                                                SectionId.Local,
                                                ItemLocalId.UpdateConfirmFilePath1)}`}>編集
                                            </FLink>
                                        </TableCell>
                                    </FTableRow>

                                    <FTableRow>
                                        <TableCellHead>更新確認ファイル2</TableCellHead>
                                        <TableCell>{mySetting.local.updateConfirmFilePath2}</TableCell>
                                        <TableCell>
                                            <FLink
                                             to={`../setting-detail/${GetKey(
                                                SettingKeyType.Machine, mySetting.machineId,
                                                SectionId.Local,
                                                ItemLocalId.UpdateConfirmFilePath2)}`}>編集
                                            </FLink>
                                        </TableCell>                                    
                                    </FTableRow>
                                    <FTableRow>
                                        <TableCellHead>更新確認ファイル3</TableCellHead>
                                        <TableCell>{mySetting.local.updateConfirmFilePath3}</TableCell>
                                        <TableCell>
                                            <FLink
                                             to={`../setting-detail/${GetKey(
                                                SettingKeyType.Machine, mySetting.machineId,
                                                SectionId.Local,
                                                ItemLocalId.UpdateConfirmFilePath3)}`}>編集
                                            </FLink>
                                        </TableCell>                                    
                                    </FTableRow>

                                    <FTableRow>
                                        <TableCellHead>更新確認フォルダ1</TableCellHead>
                                        <TableCell>{mySetting.local.updateConfirmFolderPath1}</TableCell>
                                        <TableCell>
                                        <FLink
                                             to={`../setting-detail/${GetKey(
                                                SettingKeyType.Machine, mySetting.machineId,
                                                SectionId.Local,
                                                ItemLocalId.UpdateConfirmFolderPath1)}`}>編集
                                            </FLink>
                                            </TableCell>
                                    </FTableRow>

                                    <FTableRow>
                                        <TableCellHead>更新確認フォルダ2</TableCellHead>
                                        <TableCell>{mySetting.local.updateConfirmFolderPath2}</TableCell>
                                        <TableCell>
                                        <FLink
                                             to={`../setting-detail/${GetKey(
                                                SettingKeyType.Machine, mySetting.machineId,
                                                SectionId.Local,
                                                ItemLocalId.UpdateConfirmFolderPath2)}`}>編集
                                            </FLink>
                                            </TableCell>
                                    </FTableRow>

                                    <FTableRow>
                                        <TableCellHead>更新確認フォルダ3</TableCellHead>
                                        <TableCell>{mySetting.local.updateConfirmFolderPath3}</TableCell>
                                        <TableCell>
                                        <FLink
                                             to={`../setting-detail/${GetKey(
                                                SettingKeyType.Machine, mySetting.machineId,
                                                SectionId.Local,
                                                ItemLocalId.UpdateConfirmFolderPath3)}`}>編集
                                            </FLink>
                                            </TableCell>
                                    </FTableRow>

                                    {mySetting.remoteBackup.useRemoteBackup === true &&
                                    <><FTableRow>
                                        <TableCellHead>バックアップファイル取得フォルダ1</TableCellHead>
                                        <TableCell>{mySetting.remoteBackup.backupSourceFolder1}</TableCell>
                                        <TableCell>
                                        <FLink
                                             to={`../setting-detail/${GetKey(
                                                SettingKeyType.Machine, mySetting.machineId,
                                                SectionId.RemoteBackup,
                                                ItemRemoteBackupId.BackupSourceFolder1)}`}>編集
                                            </FLink>
                                            </TableCell>
                                    </FTableRow>
                                    <FTableRow>
                                        <TableCellHead>バックアップファイル取得フォルダ2</TableCellHead>
                                        <TableCell>{mySetting.remoteBackup.backupSourceFolder2}</TableCell>
                                        <TableCell>
                                        <FLink
                                             to={`../setting-detail/${GetKey(
                                                SettingKeyType.Machine, mySetting.machineId,
                                                SectionId.RemoteBackup,
                                                ItemRemoteBackupId.BackupSourceFolder2)}`}>編集
                                            </FLink>
                                            </TableCell>
                                    </FTableRow>
                                    <FTableRow>
                                        <TableCellHead>バックアップファイル取得フォルダ3</TableCellHead>
                                        <TableCell>{mySetting.remoteBackup.backupSourceFolder3}</TableCell>
                                        <TableCell>
                                        <FLink
                                             to={`../setting-detail/${GetKey(
                                                SettingKeyType.Machine, mySetting.machineId,
                                                SectionId.RemoteBackup,
                                                ItemRemoteBackupId.BackupSourceFolder3)}`}>編集
                                            </FLink>
                                            </TableCell>
                                    </FTableRow>
                                    <FTableRow>
                                        <TableCellHead>バックアップファイル取得フォルダ4</TableCellHead>
                                        <TableCell>{mySetting.remoteBackup.backupSourceFolder4}</TableCell>
                                        <TableCell>
                                        <FLink
                                             to={`../setting-detail/${GetKey(
                                                SettingKeyType.Machine, mySetting.machineId,
                                                SectionId.RemoteBackup,
                                                ItemRemoteBackupId.BackupSourceFolder4)}`}>編集
                                            </FLink>
                                            </TableCell>
                                    </FTableRow>
                                    <FTableRow>
                                        <TableCellHead>バックアップファイル取得フォルダ5</TableCellHead>
                                        <TableCell>{mySetting.remoteBackup.backupSourceFolder5}</TableCell>
                                        <TableCell>
                                        <FLink
                                             to={`../setting-detail/${GetKey(
                                                SettingKeyType.Machine, mySetting.machineId,
                                                SectionId.RemoteBackup,
                                                ItemRemoteBackupId.BackupSourceFolder5)}`}>編集
                                            </FLink>
                                            </TableCell>
                                    </FTableRow>
                                    </>}

                                </FTableBody>
                            </FTable>
                            </>}
                            
                            </div>

                        }

                    </div>


                }
            </MainTemplate2>
    )
};