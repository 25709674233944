import React from "react";
import { FComponentProps } from "./FComponentProps";

export const Title: React.FC<FComponentProps> = ({className, children}) => {
    
    return (
        <div className={`text-xl font-medium text-green-650 ${className}`}>
            {children}
        </div>)
};

export const FLabel: React.FC<FComponentProps> = ({className, children}) => {
    
    return (
        <label className={`${className} p-2`}>
            {children}
        </label>)
};
