import { useEffect, useState } from 'react';
import TableHead from '@mui/material/TableHead';
import { Title } from '../../components/Title';
import { MainTemplate2 } from '../../templates/MainTemplate2';
import { Button, IconButton, Stack, TableContainer, Tooltip, Checkbox, Typography } from '@mui/material';
import axios from "axios";
import { useAuth } from '../../components/AuthContextProvider';
import { Navigate, useLocation } from 'react-router-dom';
import { AccountItem, MyComponentProps } from '../../types/TypeDefinition';
import * as Routes from "../../ApiRoute";
import { FButton } from '../../components/FButton';
import { InputDialog } from '../../components/InputDialog';
import { AccountRequest } from '../../types/TypeDefinition';
import { FTable } from '../../components/FTable';
import { FTableRow } from '../../components/FTableRow';
import { FTableBody } from '../../components/FTableBody';
import { TableCell } from '../../components/TableCell';
import { TableCellHead } from '../../components/TableCellHead';
import { FLink, LinkButton } from '../../components/FLink';

export const AccountList: React.FC<MyComponentProps> = () => {
    const { loginItem } = useAuth();
    const [items, setItems] = useState<Array<AccountItem>>();
    const location = useLocation();
    const [isAuthError, setIsAuthError] = useState(false);

    useEffect(() => {
        const getPost = async () => {
            try {
                const response = await axios.get(Routes.Account.GetAll, {
                    headers: {
                        Authorization: `Bearer ${loginItem.token}`,
                    }
                });
                setItems(response.data);
            }
            catch (error: any) {
                console.log(error.response.status);
                switch (error.response?.status) {
                    case 401:
                        setIsAuthError(true);
                        break;
                    default:
                        break
                }
            };
        }
        getPost();

    }, []);



    const NavigateLogin = () => {
        const origin = (location.state as any)?.from?.pathname || '/login';
        return <Navigate to={origin} />;
    }

    return (
        isAuthError ? NavigateLogin() :
            <MainTemplate2>
                    <Title className='ml-2' >ユーザー一覧</Title>
                    <div className='m-2'>
                        <FTable>
                            <thead>
                                <FTableRow>
                                    <TableCellHead>アカウント番号</TableCellHead>
                                    <TableCellHead>ユーザーID</TableCellHead>
                                    <TableCellHead>ユーザー名</TableCellHead>
                                    <TableCellHead>ユーザー種別</TableCellHead>
                                    <TableCellHead>メールアドレス</TableCellHead>
                                    <TableCellHead>削除済</TableCellHead>
                                </FTableRow>
                            </thead>
                            <FTableBody>
                                {items?.map(item =>
                                    <FTableRow key={item.userId}>
                                        <TableCell>{item.account?.code}</TableCell>
                                        <TableCell>{item.userId}</TableCell>
                                        <TableCell>{item.account?.text}</TableCell>
                                        <TableCell>{item.userRoleId}</TableCell>
                                        <TableCell>{item.emailAddress}</TableCell>
                                        <TableCell>
                                            <Checkbox checked={(item.deleteFlag)}
                                                color="default" disabled></Checkbox>
                                        </TableCell>
                                        <TableCell>
                                            <FLink to={`../account-edit/${item.account.code}`}>
                                            編集</FLink>
                                        </TableCell>
                                    </FTableRow>)}
                            </FTableBody>
                        </FTable>

                        <LinkButton className='mt-2' to='../account-edit/0'>追加</LinkButton>
                    </div>

                   
            </MainTemplate2>
    );
};

