import './App.css';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider} from './components/AuthContextProvider';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { ProtectedRoute } from './components/ProtectedRoute';
import { AlertList } from './pages/Alert/AlertList';
import { AlertHelp } from './pages/Alert/AlertHelp';
import { MonitoringResultDetail } from './pages/MonitoringResult/MonitoringResultDetail';
import { MedicalFacilityList } from './pages/MedicalFacility/MedicalFacilityList';
import { MedicalFacilityDetail } from './pages/MedicalFacility/MedicalFacilityDetail';
import { MedicalFacilityEdit } from './pages/MedicalFacility/MedicalFacilityEdit';
import { MedicalFacilitySetting } from './pages/MedicalFacility/MedicalFacilitySetting';
import { EventLog } from './pages/EventLog';
import { AdministratorSetting } from './pages/AdministratorSetting';
import { SettingList } from './pages/Setting/SettingList';
import { SettingDetail } from './pages/Setting/SettingDetail';
import { SettingEntry } from './pages/Setting/SettingEntry';
import { StorageUpload } from './pages/Storage/StorageUpload';
import { MonitoringResultList } from './pages/MonitoringResult/MonitoringResultList';
import { MonitoringResultListForMachine } from './pages/MonitoringResult/MonitoringResultListForMachine';
import { MonitoringResultListForFacility } from './pages/MonitoringResult/MonitoringResultListForFacility';
import { FkartetSettingList } from './pages/FkartetSetting/FkartetSettingList';
import { SettingCopy } from './pages/Setting/SettingCopy';
import { AccountList } from './pages/Account/AccountList';
import { AccountEdit } from './pages/Account/AccountEdit';
import { MachineInfoDetail } from './pages/MachineInfo/MachineInfoDetail';
import { FkartetBackupList } from './pages/FkartetBackup/FkartetBakupList';

const App = () => {

    return (
        <>
            <AuthProvider>
                <Routes>
                    <Route path='login' element={<Login />} />
                    <Route element={<ProtectedRoute />}>
                        <Route index element={<Home />} />
                        <Route path="home" element={<Home />} />
                        <Route path="machine-info-detail/:machineId" element={<MachineInfoDetail />} />
                        <Route path="monitoring-result-list" element={<MonitoringResultList />} />
                        <Route path="monitoring-result-list-machine/:machineId" element={<MonitoringResultListForMachine />} />
                        <Route path="monitoring-result-list-facility/:medicalId" element={<MonitoringResultListForFacility />} />
                        <Route path="monitoring-result-detail/:id" element={<MonitoringResultDetail />} />
                        <Route path="medical-facility-detail/:medicalId" element={<MedicalFacilityDetail />} />
                        <Route path="medical-facility-edit/:medicalId" element={<MedicalFacilityEdit />} />
                        <Route path="medical-facility-list/" element={<MedicalFacilityList />} />
                        <Route path="medical-facility-setting/:medicalId" element={<MedicalFacilitySetting />} />
                        <Route path="fkartet-setting-list/" element={<FkartetSettingList />} />
                        <Route path="eventlog/getlog/:id" element={<EventLog />} />
                        <Route path="alert-list" element={<AlertList />} />
                        <Route path="alert-help" element={<AlertHelp />} />
                        <Route path="admin-setting" element={<AdministratorSetting />} />
                        <Route path="account-list" element={<AccountList />} />
                        <Route path="account-edit/:accountId" element={<AccountEdit />} />
                        <Route path="setting-list" element={<SettingList />} />
                        <Route path="setting-detail/:key" element={<SettingDetail />} />
                        <Route path="setting-copy/:key" element={<SettingCopy />} />
                        <Route path="setting-entry" element={<SettingEntry />} />
                        <Route path="storage-upload" element={<StorageUpload />} />
                        <Route path="fkartet-backup-list" element={<FkartetBackupList />} />
                    </Route>

                    <Route path="*" element={<NoMatch />} />
                </Routes>
            </AuthProvider>
        </>
    );
};

const NoMatch = () => {
    return (
        <h2>Not Found</h2>
    );
};

export default App;
