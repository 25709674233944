import { useEffect, useState } from "react";
import { useAuth } from '../../components/AuthContextProvider';
import { MainTemplate2 } from '../../templates/MainTemplate2';
import axios from "axios";
import { Navigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MedicalFacilityRequest, MyComponentProps } from "../../types/TypeDefinition";
import { MedicalFacilityItem } from "../../types/TypeDefinition";
import { FLink } from "../../components/FLink";
import { FTable } from "../../components/FTable";
import { TableCellHead } from "../../components/TableCellHead";
import { FTableBody } from "../../components/FTableBody";
import { FTableRow } from "../../components/FTableRow";
import { TableCell } from "../../components/TableCell";
import { LinkButton } from "../../components/FLink";
import { Title } from "../../components/Title";
import * as Routes from "../../ApiRoute";
import { InputDialog } from "../../components/InputDialog";
import { FButton } from "../../components/FButton";
import { FInput } from "../../components/FInput";
import { GetMonitoringStatusText } from "../../functions/MedicalFacility";

export const MedicalFacilityList: React.FC<MyComponentProps> = () => {
    const { loginItem } = useAuth();
    const [items, setItems] = useState<Array<MedicalFacilityItem>>();
    const location = useLocation();
    const [isAuthError, setIsAuthError] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [contains, setContains] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [filteredItems, setFilteredItems] = 
        useState<Array<MedicalFacilityItem> | null>();

    useEffect(() => {
        const getPost = async () => {
            try {
                const response = await axios.get(Routes.MedicalFacility.GetAll, {
                    headers: {
                        Authorization: `Bearer ${loginItem.token}`,
                    }
                });
                setItems(response.data);
                setIsChanged(false);
            }
            catch (error: any) {
                console.log(error.response);
                switch (error.response?.status) {
                    case 401:
                        setIsAuthError(true);
                        break;
                    default:
                        break;
                }
            }
        }
        getPost();
    }, [isChanged]);

    useEffect(()=> {
        if (items && keyword) {
            const filtered = items.filter(item =>
                item.medicalInstitution.text.toUpperCase()
                .includes(keyword.toUpperCase()) ||
                item.medicalInstitution.code.toUpperCase()
                .includes(keyword.toUpperCase())
            );
            setFilteredItems(filtered);
        } else {
            setFilteredItems(items);
        }
    }, [items, keyword])

    const openModal = () => {
        setContains(false);
        setIsOpen(true);
    } 

    const closeModal = () => {
        setIsChanged(true);
        setIsOpen(false);
    }

    const handleInputChange = 
        (event: React.ChangeEvent<HTMLInputElement>) => 
        {
            setKeyword(event.target.value);
        };

    const handleConfirm = async (id:string, text:string) => {

        if (!id || !text) {
            setContains(false);
            return;
        }

        try {
            console.log(`ID confirmed: ${id} text: ${text}`);
            
            const response = await axios.get(`${Routes.MedicalFacility.Contains}/${id}`, {
                headers: {
                    Authorization: `Bearer ${loginItem.token}`,
                }
            });
            console.log("contain:" + response.data);

            if (response.data) {
                setContains(response.data);
            }
            else {
                setContains(false);

                const reqItem: MedicalFacilityRequest = {
                    medicalId: id,
                    name: text
                }

                const registerResponse = await axios.post(Routes.MedicalFacility.Register, 
                    reqItem,
                    {
                    headers: {
                        Authorization: `Bearer ${loginItem.token}`,
                    }
                });

                closeModal();
            }

        } catch (error: any) {
            console.error(error);
        }
   };
   
    const NavigateLogin = () => {
        const origin = (location.state as any)?.from?.pathname || '/login';
        return <Navigate to={origin} />;
    }

    return (
        isAuthError ? NavigateLogin() :
            <MainTemplate2>
                <div className="ml-2 mr-4">
                    <Title>医療機関一覧</Title>
                    <FInput className="mt-2 w-1/5" placeholder="絞り込み"
                     onChange={handleInputChange}></FInput>

                    <div className="flex w-full relative overflow-x-auto mt-2 border">
                        <FTable>
                            <thead className="h-auto">
                                <FTableRow>
                                    <TableCellHead>医療機関ID</TableCellHead>
                                    <TableCellHead>医療機関名</TableCellHead>
                                    <TableCellHead>サーバー監視</TableCellHead>
                                    <TableCellHead></TableCellHead>
                                    <TableCellHead></TableCellHead>
                                </FTableRow>
                            </thead>
                            <FTableBody>
                                {filteredItems && filteredItems.map(item =>
                                    <FTableRow className="bg-white border-b hover:bg-gray-50">
                                        <TableCell className="h-8">
                                            <Link className="text-green-650" to={`../medical-facility-detail/${item.medicalInstitution.code}`}>
                                                {item.medicalInstitution.code}
                                            </Link>
                                        </TableCell>
                                        <TableCell>{item.medicalInstitution.text}</TableCell>
                                        <TableCell>{GetMonitoringStatusText(item.monitoringStatus)}</TableCell>
                                        <TableCell>
                                            <FLink to={`../medical-facility-edit/${item.medicalInstitution.code}`}
                                            >編集
                                            </FLink>
                                        </TableCell>
                                        <TableCell>
                                            <FLink to={`../medical-facility-setting/${item.medicalInstitution.code}`}
                                            >設定
                                            </FLink>
                                        </TableCell>
                                        {/* <FTableCell>
                                            <Button
                                                color="primary"
                                                component={Link}
                                                to={`../medical-facility-setting/${item.medicalInstitution.code}`}>設定
                                            </Button>
                                        </FTableCell> */}
                                    </FTableRow>
                                )}
                            </FTableBody>
                        </FTable>
                    </div>

                    {/* <TableContainer sx={{ maxHeight: 400 }}>
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>医療機関ID</TableCell>
                                    <TableCell>医療機関名</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items && items.map(item =>
                                    <TableRow key={item.medicalInstitution.code} hover>
                                        <TableCell>
                                            <Link className="text-green-650" to={`../medical-facility-detail/${item.medicalInstitution.code}`}>
                                                {item.medicalInstitution.code}
                                            </Link>
                                        </TableCell>
                                        <TableCell>{item.medicalInstitution.text}</TableCell>
                                        <TableCell>
                                            <FLink to={`../medical-facility-detail/${item.medicalInstitution.code}`}
                                            >編集
                                            </FLink>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                color="primary"
                                                component={Link}
                                                to={`../medical-facility-setting/${item.medicalInstitution.code}`}>設定
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer> */}

                    {/* <Box>
                        <Button
                            sx={{ mt: 1 }}
                            color="primary"
                            variant='contained'
                            component={Link}
                            to="../medical-facility-entry/">追加
                        </Button>
                    </Box> */}

                    <FButton className="mt-2" onClick={openModal}>追加</FButton>

                    <InputDialog isOpen={isOpen}
                        message="医療機関IDと医療機関名を指定してください"
                        onClose={closeModal} onConfirm={handleConfirm}
                        >
                     {contains && "指定した医療機関IDは既に使用されています。"}
                </InputDialog>


                </div>
            </MainTemplate2>
    )
};