import { useEffect, useState } from "react";
import { useParams, Navigate, useLocation, useNavigate, Route } from "react-router-dom";
import { useAuth } from '../../components/AuthContextProvider';
import { MainTemplate2 } from '../../templates/MainTemplate2';
import Paper from '@mui/material/Paper';
import axios from "axios";
import { Button, Box, TableContainer, Table, TableRow, TableBody } from "@mui/material";
import { Link } from 'react-router-dom';
import { MachineInfoItem, MedicalFacilityItem, MyComponentProps } from "../../types/TypeDefinition";
import { FLink } from "../../components/FLink";
import * as Routes from "../../ApiRoute";
import { FLabel, Title } from "../../components/Title";
import { FTable } from "../../components/FTable";
import { FTableRow } from "../../components/FTableRow";
import { TableCellHead } from "../../components/TableCellHead";
import { FTableBody } from "../../components/FTableBody";
import { FButton, ButtonType } from "../../components/FButton";
import { TableCell } from "../../components/TableCell";
import { GetMachineTypeText } from "../../functions/MachineInfo";
import moment from 'moment';

export const MedicalFacilityDetail: React.FC<MyComponentProps> = () => {
    const { loginItem } = useAuth();
    const { medicalId } = useParams();
    const [items, setItems] = useState<Array<MachineInfoItem>>([]);
    const [facilityItem, setFacilityItem] = useState<MedicalFacilityItem>();
    const location = useLocation();
    const navigate = useNavigate();
    const [isAuthError, setIsAuthError] = useState(false);

    useEffect(() => {
        const f = async () => {
            try {
                const response = await axios.get(`${Routes.MedicalFacility.GetById}/${medicalId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${loginItem.token}`,
                        }
                    });
                setFacilityItem(response.data);
            }
            catch (error: any) {
                console.log(error.response);
                switch (error.response?.status) {
                    case 401:
                        setIsAuthError(true);
                        break;
                    default:
                        break;
                }
            };
        };
        f();
    }, []);

    useEffect(() => {
        const f = async () => {
            try {
                const response = await axios.get(`${Routes.MachineInfo.GetListByMedicalId}/${medicalId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${loginItem.token}`,
                        }
                    });
                console.log("Response data: ", response.data);
                setItems(response.data);
            }
            catch (error: any) {
                console.log(error.response);
                switch (error.response?.status) {
                    case 401:
                        setIsAuthError(true);
                        break;
                    default:
                        break;
                }
            };
        };
        f();
    }, []);

    const handleEdit = () => {
        navigate(`/medical-facility-edit/${facilityItem?.medicalInstitution.code}`);
    };

    const NavigateLogin = () => {
        const origin = (location.state as any)?.from?.pathname || '/login';
        return <Navigate to={origin} />;
    }

    return (
        isAuthError ? NavigateLogin() :
            <MainTemplate2>
                {facilityItem && <div>
                    <div className="flex justify-between items-center px-2 py-2">
                        <Title>
                            <span>{facilityItem.medicalInstitution.text}</span>
                        </Title>

                        <FButton onClick={handleEdit} >編集</FButton>
                    </div>

                    <div className="mt-2">
                        <FTable>
                            <FTableBody>

                                <FTableRow>
                                    <TableCellHead>医療機関コード</TableCellHead>
                                    <TableCell>{facilityItem.medicalInstitution.code}</TableCell>
                                </FTableRow>

                                <FTableRow>
                                    <TableCellHead>正式名称</TableCellHead>
                                    <TableCell>{facilityItem.formalName}</TableCell>
                                </FTableRow>

                                <FTableRow>
                                    <TableCellHead>郵便番号</TableCellHead>
                                    <TableCell>{facilityItem.postalCode}</TableCell>
                                </FTableRow>

                                <FTableRow>
                                    <TableCellHead>住所</TableCellHead>
                                    <TableCell>{facilityItem.address}</TableCell>
                                </FTableRow>

                                <FTableRow>
                                    <TableCellHead>TEL</TableCellHead>
                                    <TableCell>{facilityItem.tel}</TableCell>
                                </FTableRow>

                                <FTableRow>
                                    <TableCellHead>FAX</TableCellHead>
                                    <TableCell>{facilityItem.fax}</TableCell>
                                </FTableRow>

                                <FTableRow>
                                    <TableCellHead>メールアドレス</TableCellHead>
                                    <TableCell>{facilityItem.eMailAddress}</TableCell>
                                </FTableRow>
                                <FTableRow>
                                    <TableCellHead>ホームページ</TableCellHead>
                                    <TableCell>{facilityItem.homepageUrl}</TableCell>
                                </FTableRow>
                                <FTableRow>
                                    <TableCellHead>代表者</TableCellHead>
                                    <TableCell>{facilityItem.representative}</TableCell>
                                </FTableRow>
                                <FTableRow>
                                    <TableCellHead>担当者1</TableCellHead>
                                    <TableCell>{facilityItem.staff1}</TableCell>
                                </FTableRow>
                                <FTableRow>
                                    <TableCellHead>担当者2</TableCellHead>
                                    <TableCell>{facilityItem.staff2}</TableCell>
                                </FTableRow>
                                <FTableRow>
                                    <TableCellHead>備考</TableCellHead>
                                    <TableCell>{facilityItem.note}</TableCell>
                                </FTableRow>
                                <FTableRow>
                                    <TableCellHead>監視状態</TableCellHead>
                                    <TableCell>{facilityItem.monitoringStatus}</TableCell>
                                </FTableRow>

                            </FTableBody>

                        </FTable>
                    </div>

                    {items &&
                        <div>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                {/* <div>{item.medicalInstitution?.code}</div>
                <div>{item.medicalInstitution?.text}</div> */}
                                <div>端末一覧</div>
                            </Paper>

                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                <FTable>
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <FTableRow>
                                            {/* <th scope="col" className="px-6 py-3">
                                                端末ID
                                            </th> */}
                                            <TableCell>利用種別</TableCell>
                                            <TableCell>PC名</TableCell>
                                            <TableCell>IPアドレス</TableCell>
                                            <TableCell>ライセンスキー</TableCell>
                                            <TableCell>最終起動日時</TableCell>
                                            <TableCell></TableCell>
                                        </FTableRow>
                                    </thead>
                                    <FTableBody>
                                        {items && items.map(
                                            item =>
                                                <FTableRow>
                                                    {/* <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                        {item.machineId}
                                                    </th> */}
                                                    <TableCell>{GetMachineTypeText(item.machineType)}</TableCell>
                                                    <TableCell>{item.pcName}</TableCell>
                                                    <TableCell>{item.ipAddress}</TableCell>
                                                    <TableCell>{item.licenseKey}</TableCell>
                                                    <TableCell>{item.lastUsedDate && moment(item.lastUsedDate).format('YY/MM/DD HH:mm')}</TableCell>
                                                    <TableCell>
                                                        <FLink to={`../machine-info-detail/${item.machineId}`}
                                                        >詳細</FLink>
                                                    </TableCell>
                                                </FTableRow>
                                        )}
                                    </FTableBody>
                                </FTable>
                            </div>

                        </div>

                    }

                    {/* <div className="mt-2">
                        <FLabel className="text-sm">※最終起動日時が古い場合、その端末は使用されていないか、fカルテットを長期間再起動していないかのいずれかです。
                            必要に応じて対象施設にリモート接続し、[端末情報] から該当レコードを削除してください。
                            本画面の該当端末の詳細ページからの削除も必要です。(削除情報は連携されません)
                        </FLabel>
                    </div> */}
                </div>
                }
            </MainTemplate2>
    )
};