export const ApiBase = "/api/";
export const AccountController = "account/";
export const AlertController = "alert/";
export const AuthenticationController = "authentication/";
export const BackupController = "backup/";
export const DbRecordCountController = "dbrecordcount/";
export const EventlogController = "eventlog/";
export const FkartetSettingController = "fkartetsetting/";
export const MachineInfoController = "machineinfo/";
export const MedicalFacilityController = "medicalfacility/";
export const MonitoringResultController = "monitoringresult/";
export const SettingController = "setting/";
export const StorageController = "storage/";

export const Account = {
    GetAll: ApiBase + AccountController + "all",
    GetById: ApiBase + AccountController + "id",
    Contains: ApiBase + AccountController + "contains",
    Register: ApiBase + AccountController + "register"
}

export const Alert = {
    GetList: ApiBase + AlertController + "list",
    RegisterList: ApiBase + AlertController + "register/list"
}

export const Authentication = {
    Login: ApiBase + AuthenticationController + "login",
}

export const Bacukup = {
    GetList: ApiBase + BackupController + "list",
}

export const DbRecordCount = {
    Register: ApiBase + DbRecordCountController + "register",
    MedicalId: ApiBase + DbRecordCountController + "medicalid",
    GetCarefully: ApiBase + DbRecordCountController + "carefully"
}

export const EventLog = {
    GetById: ApiBase + EventlogController + "id",
    Register: ApiBase + EventlogController + "register"
}

export const FkartetSetting = {
    RegisterList: ApiBase + FkartetSettingController + "register/list",
    GetFunctionList: ApiBase + FkartetSettingController + "function/list",
    GetListBySettingId: ApiBase + FkartetSettingController + "settingid/list",
}

export const MachineInfo = {
    GetById: ApiBase + MachineInfoController + "id",
    GetListByMedicalId: ApiBase + MachineInfoController + "medicalid/list",
    GetMainServer: ApiBase + MachineInfoController + "mainserver",
    DeleteById: ApiBase + MachineInfoController + "id",
    ChangeMonitoringFlag: ApiBase + MachineInfoController + "monitoringflag"
}

export const MedicalFacility = {
    GetAll: ApiBase + MedicalFacilityController + "all",
    GetById: ApiBase + MedicalFacilityController + "id",
    Register: ApiBase + MedicalFacilityController + "register",
    DeleteById: ApiBase + MedicalFacilityController + "id",
    Contains: ApiBase + MedicalFacilityController + "contains"
}

export const MonitoringResult = {
    GetAll: ApiBase + MonitoringResultController + "all",
    GetById: ApiBase + MonitoringResultController + "id",
    Register: ApiBase + MonitoringResultController + "register",
    GetLatestList: ApiBase + MonitoringResultController + "latest/list",
    GetElementListById: ApiBase + MonitoringResultController + "element/list/id",
    GetListByMedicalId: ApiBase + MonitoringResultController + "list/medicalid",
    GetListByMachineId: ApiBase + MonitoringResultController + "list/machineid",
    GetCarefullyListByMonitoringDate: ApiBase + MonitoringResultController 
        + "carefully/list/monitoringdate",
    GetCarefullyListByStorage: ApiBase + MonitoringResultController
        + "carefully/list/storage"
}

export const Setting = {
    GetAll: ApiBase + SettingController + "all",
    Register: ApiBase + SettingController + "register",
    GetByKey: ApiBase + SettingController + "key",
    GetMySetting: ApiBase + SettingController + "mysetting",
    GetForMachine: ApiBase + SettingController + "machine",
    Delete: ApiBase + SettingController + "delete"
}

export const Storage = {
    GetById: ApiBase + StorageController + "id",
    Upload: ApiBase + StorageController + "upload"
}
