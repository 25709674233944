import { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useAuth } from "../../components/AuthContextProvider";
import { LinearProgress, Box, Typography, Paper } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { useUploadForm } from "../../hooks/useUploadForm";
import * as Routes from "../../ApiRoute";
import { StorageUploadFormProps } from "./StorageUpload";

// ファイルアップロードForm
export const StorageUploadForm = (props: StorageUploadFormProps) => {
    const { loginItem } = useAuth();
    const { isSuccess, uploadForm, progress } = useUploadForm(Routes.Storage.Upload);
    const [fileName, setFileName] = useState("");
    const [displayedProgress, setDisplayedProgress] = useState(0);

    useEffect(()=> {
        const timer = setInterval(()=> {
            if (displayedProgress <progress) {
                setDisplayedProgress(prevProgress => prevProgress +1);
            }
        }, 30); // 100msごとに表示更新

        return () => {
            clearInterval(timer);
        };
    }, [progress]);

    const onDrop = useCallback((acceptedFiles) => {
        const data = new FormData();
        data.append("storageId", props.storageId);
        data.append("accountId", loginItem.account.code.toString());
        data.append("file", acceptedFiles[0]);

        setFileName(acceptedFiles[0].name);

        setDisplayedProgress(0);

        const f = async () => { await uploadForm(data) };
        f();

    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div>
            <div>{props.title}</div>
            <Paper sx={{ mt: 2, p: 2, display: 'flex', flexDirection: 'column' }}>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>ここにドロップしてください。</p> :
                            fileName ? fileName :
                                <p>ドラッグ&ドロップするか、ここをクリックしてファイルを選択してください。</p>
                    }
                </div>

            </Paper>
            <Box sx={{ mt: 3 }}>
                {isSuccess ? (
                    <Box color="success.main" display="flex">
                        <CheckIcon color="success" />
                        <Typography>完了</Typography>
                    </Box>
                ) : (
                    <Box sx={{ mt: 3 }}>
                        <LinearProgress variant="determinate" value={displayedProgress} />
                    </Box>)}
            </Box>
        </div>
    );
};
