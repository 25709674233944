import React from "react";
import { Link } from 'react-router-dom';

interface FLinkProps {
      className?: string;
      to: string;
      children?: React.ReactNode;
}

export const FLink: React.FC<FLinkProps> = ({ className, to, children }) => {

      return (
            <Link className={`font-medium text-green-650 hover:underline ${className}`}
                  to={to}>
                  {children}
            </Link>);
};

export const LinkButton: React.FC<FLinkProps> = ({ className, to, children }) => {

      return (
            <Link className={`text-white bg-green-650
            hover:bg-green-800 
            focus:ring-green-500 font-medium rounded-lg text-sm px-4 py-2 mr-2 mb-2 
             focus:outline-none ${className}`}
                  style={{display: 'inline-block'}}
                  to={to}>
                  {children}
            </Link>);
};
