import { CheckCircle, Iso } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';

interface InputProps {
  className?: string;
  isOpen: boolean;
  message?: string;
  onClose: () => void;
  onConfirm: (id:string, text:string) => Promise<void>;
  children?: React.ReactNode;
}

export const InputDialog: React.FC<InputProps> = ({ className, isOpen, message, onClose, onConfirm, children }) => {
    const [id, setId] = useState('');
    const [text, setText] = useState("");

    useEffect(() => {
      if (isOpen) {
        setId('');
        setText("");
      }
    }, [isOpen])

  // if (!isOpen) {
  //   return null;
  // }

  const handleConfirm = async () => {
    try {
        await onConfirm(id, text);
    } catch (error) {
        console.error("Error:", error);
    }
  }

  return (
    <div className={`${isOpen ? 'block' : 'hidden'} fixed z-10 inset-0 overflow-y-auto ${className}`}>
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>

        <div className="relative mx-auto w-full max-w-lg mt-5 px-4 py-8 bg-white shadow-lg rounded">
          <h2 className="text-2xl mb-4">{message ?? "IDとテキストを入力してください"}</h2>
          <input
            type="text"
            placeholder="IDを入力"
            value={id}
            onChange={e => setId(e.target.value)}
            className="w-full p-2 border rounded"
          />
           <input 
            type="text"
            placeholder="テキストを入力"
            value={text}
            onChange={e => setText(e.target.value)}
            className="w-full mt-2 p-2 border rounded"
          />
          <div className="mt-4 flex justify-end">
            <button onClick={onClose} className="px-4 py-2 bg-gray-400 text-white rounded mr-2">
              キャンセル
            </button>
            <button onClick={handleConfirm} className="px-4 py-2 bg-green-650 text-white rounded">
              確定
            </button>
          </div>

          <div className='mt-2 text-orange-500'>
            {children}
          </div>
        </div>
      </div>
      </div>
  );
};
