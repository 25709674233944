import react, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../components/AuthContextProvider';
import { MainTemplate } from '../../templates/MainTemplate';
import {
    Button, Box, TableContainer, Table, TableRow, TableCell,
    TableHead, TableBody, TextField, Paper, Typography, InputLabel, Stack
} from "@mui/material";
import { AirlineSeatIndividualSuiteSharp, ErrorOutline, Label, SignalCellularConnectedNoInternet4BarRounded } from '@mui/icons-material';
//import { FetchData } from '../FetchUtility';
import { FetchPost } from '../../utils/FetchUtility';
//import { FetchDelete } from '../FetchUtility';
import { Title } from '../../components/Title';
import { DataGrid, GridColDef, gridColumnsTotalWidthSelector, GridToolbar, jaJP } from '@mui/x-data-grid';
import { useParams } from "react-router-dom";
import { useNavigate, Link, Navigate, useLocation } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import * as Routes from "../../ApiRoute";
import { MyComponentProps, SettingItem } from '../../types/TypeDefinition';
import { MainTemplate2 } from '../../templates/MainTemplate2';
import { FButton, ButtonType } from '../../components/FButton';
import { SettingItemToRequest } from '../../functions/Setting';

export const SettingCopy : React.FC<MyComponentProps> = () => {
    const { loginItem } = useAuth();
    const [item, setItem] = useState<SettingItem>();
    const { key } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [isAuthError, setIsAuthError] = useState(false);

    useEffect(() => {
        const f = async () => {
            try {
                const response = await axios.get(`${Routes.Setting.GetByKey}/${key}`,
                    {
                        headers: {
                            Authorization: `Bearer ${loginItem.token}`,
                        }
                    });
                const data = response.data;
                setItem(data);
            }
            catch (error: any) {
                console.log(error.response);
                switch (error.response?.status) {
                    case 401:
                        setIsAuthError(true);
                        break;
                    default:
                        break;
                }
            }
        };
        f();
    }, []);

    const handleSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (!item) {
            return;
        }

        const request = SettingItemToRequest(item, loginItem.account.code);

        await FetchPost(Routes.Setting.Register, loginItem.token,
            request
        );

        // 元のページに戻る
        navigate(-1);
    };

    const handleCancel = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        // 元のページに戻る
        navigate(-1);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (!item) {
            return;
        }

        const target = e.target;
        setItem({
            ...item,
            [target.name]: target.value
        })
    };

    const NavigateLogin = () => {
        const origin = (location.state as any)?.from?.pathname || '/login';
        return <Navigate to={origin} />;
    }
    
    return (
        isAuthError ? NavigateLogin() :
            <MainTemplate2>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Title>設定コピー</Title>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                    </Box>

                    {item && <div>
                        <TableContainer sx={{ maxHeight: 400 }}>
                            <Table size="small" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>KeyType</TableCell>
                                        <TableCell>KeyValue</TableCell>
                                        <TableCell>SectionId</TableCell>
                                        <TableCell>ItemId</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow hover>
                                        <TableCell>
                                            <TextField sx={{ bgcolor: 'white' }}
                                                margin="none"
                                                id='keyType'
                                                name='keyType'
                                                value={item.keyType}
                                                onChange={handleChange}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField sx={{ bgcolor: 'white' }}
                                                margin="none"
                                                id='keyValue'
                                                name='keyValue'
                                                value={item.keyValue}
                                                onChange={handleChange}
                                            /></TableCell>
                                        <TableCell>
                                            <TextField sx={{ bgcolor: 'white' }}
                                                margin="none"
                                                id='sectionId'
                                                name='sectionId'
                                                value={item.section.code}
                                                onChange={handleChange}
                                            /></TableCell>
                                        <TableCell>
                                            <TextField sx={{ bgcolor: 'white' }}
                                                margin="none"
                                                id='itemId'
                                                name='itemId'
                                                value={item.item.code}
                                                onChange={handleChange}
                                            /></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Box component="form" noValidate autoComplete='off'
                            sx={{ mt: 0, p: 1 }}>

                            <Typography component="h4"
                                sx={{ mt: 3, mb: 1 }}>ItemValue</Typography>
                            <TextField sx={{ bgcolor: 'white' }}
                                margin="none"
                                fullWidth
                                id='itemValue'
                                name='itemValue'
                                value={item.itemValue}
                                onChange={handleChange}
                            />

                            <FButton onClick={handleCancel} buttonType={ButtonType.Cancel}
                            >キャンセル</FButton>

                            <FButton
                                onClick={handleSave}
                            >保存</FButton>

                        </Box>
                    </div>}
                </Paper>
            </MainTemplate2>
    );
};


