import { MachineType } from "../types/TypeDefinition";

export function GetMachineTypeText(machineType?: number) {

    if (!machineType) {
        return "";
    }

    switch (machineType) {
        case MachineType.MainServer:
            return "主サーバー"
        case MachineType.SubServer:
            return "副サーバー"
        case MachineType.Client:
            return "クライアント"
    }
}