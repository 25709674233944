import React from "react";

export enum ButtonType {
    // 通常使用するボタン(OKボタンなど)
    Normal = 1,
    // 目立たない色でフォーカスがある場合のみ目立たせる(削除ボタンなど)
    Carefullly = 2,
    Cancel = 3,
}

// 参考：MUIの定義 Primary,Secondary,Error,Warning,Info,Success

export type FButtonComponentProps = {
    className?: string;
    children?: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    buttonType?: ButtonType;
};

export const FButton: React.FC<FButtonComponentProps> =
    ({ className, children, onClick, buttonType = ButtonType.Normal }) => {

        let buttonStyle = '';

        switch (buttonType) {
            case ButtonType.Normal:
                buttonStyle = 'text-white bg-green-650 hover:bg-green-700 focus:ring-2 ' +
                    'focus:ring-green-700';
                break;
            case ButtonType.Carefullly:
                buttonStyle = 'text-white bg-gray-500 hover:bg-red-600 focus:ring-2 ' +
                    'focus:ring-red-600';
                break;
            case ButtonType.Cancel:
                buttonStyle = 'text-white bg-gray-400 hover:bg-gray-500 focus:ring-2 ' +
                    'focus:ring-gray-500';
                break;
            default:
                buttonStyle = 'text-white bg-green-650 hover:bg-green-700 focus:ring-2 ' +
                    'focus:ring-green-700';
        }

        return (
            <button type="button"
                className={`${buttonStyle} font-medium rounded-lg text-sm px-5 
                py-2.5 mr-2 mb-2 focus:outline-none ${className}`}
                onClick={onClick}
            >
                {children}
            </button>
        );
    };



