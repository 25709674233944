import { useEffect, useState } from "react";
import { useAuth } from '../../components/AuthContextProvider';
import { MainTemplate2 } from '../../templates/MainTemplate2';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Title } from "../../components/Title";
import axios from "axios";
import { Button, TableContainer, Link } from '@mui/material';
import { Navigate, useLocation } from 'react-router-dom';
import { FkartetSettingItem, MyComponentProps } from "../../types/TypeDefinition";
import * as Routes from "../../ApiRoute";

export const FkartetSettingList: React.FC<MyComponentProps> = () => {
    const { loginItem } = useAuth();
    const [items, setItems] = useState<Array<FkartetSettingItem>>();
    const location = useLocation();
    const [isAuthError, setIsAuthError] = useState(false);

    useEffect(() => {
        const getPost = async () => {
            try {
                const response = await axios.get(Routes.FkartetSetting.GetListBySettingId, {
                    headers: {
                        Authorization: `Bearer ${loginItem.token}`,
                    },
                    params: {
                        sectionId: 2,
                        itemId: 10
                    }
                });
                setItems(response.data);
            }
            catch (error: any) {
                console.log(error.response);
                switch (error.response?.status) {
                    case 401:
                        setIsAuthError(true);
                        break;
                    default:
                        break;
                }
            };
        }
        getPost();
    }, []);

    const NavigateLogin = () => {
        const origin = (location.state as any)?.from?.pathname || '/login';
        return <Navigate to={origin} />;
    }

    return (
        isAuthError ? NavigateLogin() :
        <MainTemplate2>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Title>fカルテット設定内容</Title>
                <TableContainer sx={{ maxHeight: 400 }}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>MedicalId</TableCell>
                                <TableCell>医療機関名</TableCell>
                                <TableCell>SectionId</TableCell>
                                <TableCell>ItemId</TableCell>
                                <TableCell sx={{ minWidth: 200 }}>ItemValue</TableCell>
                                {/* <TableCell></TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items?.map(item =>
                                <TableRow key={item.key} hover>
                                    <TableCell>{item.medicalInstitution?.code}</TableCell>
                                    <TableCell>{item.medicalInstitution?.text}</TableCell>
                                    <TableCell>{item.sectionId}</TableCell>
                                    <TableCell>{item.itemId}</TableCell>
                                    <TableCell>{item.itemValue}</TableCell>
                                    {/* <TableCell>
                                            <Button
                                             color="primary"
                                             component={Link}
                                             to={`../monitor-program-setting-detail/${item.key}`}>編集
                                            </Button>
                                        </TableCell> */}
                                </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </MainTemplate2>
    )
};