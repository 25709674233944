import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { Title } from '../../components/Title';
import TableRow from '@mui/material/TableRow';
import { MainTemplate2 } from '../../templates/MainTemplate2';
import { Button, IconButton, Stack, TableContainer, Tooltip, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axios from "axios";
import { useAuth } from '../../components/AuthContextProvider';
import { Navigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { AlertItem, MyComponentProps } from '../../types/TypeDefinition';
import * as Routes from "../../ApiRoute";

const AlertLevelType = {
    Critical: 1,
    Warning: 2,
    Information: 3
};

export const AlertList:React.FC<MyComponentProps> = () => {
    const { loginItem } = useAuth();
    const [resultItems, setResultItems] = useState<Array<AlertItem>>();
    const [isLoadingResult, setIsLoadingResult] = useState(false);
    const location = useLocation();
    const [isAuthError, setIsAuthError] = useState(false);

    const params = {
        lowerLimit: 2000,
        limit: 50
    };

    useEffect(() => {
        setIsLoadingResult(true);
        const getPost = async () => {
            try {
                const response = await axios.get(Routes.Alert.GetList, {
                    headers: {
                        Authorization: `Bearer ${loginItem.token}`,
                    }
                });
                setResultItems(response.data);
                setIsLoadingResult(false);
            }
            catch (error: any) {
                console.log(error.response);
                switch (error.response?.status) {
                    case 401:
                        setIsAuthError(true);
                        break;
                    default:
                        break;
                }
            };
        }
        getPost();

    }, []);

    const NavigateLogin = () => {
        const origin = (location.state as any)?.from?.pathname || '/login';
        return <Navigate to={origin} />;
    }

    return (
        isAuthError ? NavigateLogin() :
            <MainTemplate2>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Title>監視結果アラート一覧</Title>
                    <Stack sx={{ mt: -5, mb: 1, mr: 1 }} direction="row" justifyContent="end">
                        <Tooltip title="監視内容">
                            <IconButton component={Link} to='/alert-help'>
                                <HelpOutlineIcon color='primary' />
                            </IconButton>

                        </Tooltip>
                    </Stack>

                    {isLoadingResult ?
                        <Box>
                            <Stack direction="row">
                                <Typography sx={{ mt: 1, mr: 2 }}>監視結果判定中です...</Typography>
                                <CircularProgress />
                            </Stack>
                        </Box> :
                        <TableContainer sx={{ maxHeight: 700 }}>
                            <Table size="small" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>詳細</TableCell>
                                        <TableCell>医療機関</TableCell>
                                        <TableCell>端末ID</TableCell>
                                        <TableCell>項目</TableCell>
                                        <TableCell>レベル</TableCell>
                                        <TableCell>監視結果</TableCell>
                                        <TableCell>メッセージ</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {resultItems &&
                                        resultItems.map(item =>
                                            <TableRow key={item.machineId} hover>
                                                <TableCell>{item.monitoringResultId &&
                                                    <Link className="text-green-650" to={`../monitoring-result-detail/${item.monitoringResultId}`}>
                                                        詳細
                                                    </Link>}
                                                </TableCell>
                                                <TableCell>{item.medicalInstitution.text}</TableCell>
                                                <TableCell>
                                                    <Link className="text-green-650" to={`../monitoring-result-list-machine/${item.machineId}`}>
                                                        {item.machineId}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>{item.alertName}</TableCell>
                                                <TableCell>
                                                    <Typography color={getColor(item.alertLevel.code)}>
                                                        {item.alertLevel.text}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>{item.monitoringResult}</TableCell>
                                                <TableCell>
                                                    <Typography color={getColor(item.alertLevel.code)}>
                                                        {item.alertMessage}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>)}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                </Paper>
            </MainTemplate2>
    );
};

const getColor = (level: number) => {

    switch (level) {
        case AlertLevelType.Critical:
            return 'error';
        case AlertLevelType.Warning:
            // 何故か 'warning' だと効かない
            return '#ED6C03';
        case AlertLevelType.Information:
            return 'info';
        default:
            return 'Black';
    }
};