import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { Title } from '../../components/Title';
import TableRow from '@mui/material/TableRow';
import { MainTemplate2 } from '../../templates/MainTemplate2';
import { Button, Grid, IconButton, Stack, TableContainer, Tooltip, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axios from "axios";
import { useAuth } from '../../components/AuthContextProvider';
import { Navigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { tableCellClasses } from "@mui/material/TableCell";
import { BackupStorageItem, MyComponentProps } from '../../types/TypeDefinition';
import * as Routes from "../../ApiRoute";

const StatusType = {
    Normal: 1,
    NotFound: 2,
    OutOfService: 3,
    Stopped: 4
};

export const FkartetBackupList: React.FC<MyComponentProps> = () => {
    const { loginItem } = useAuth();
    const [items, setItems] = useState([]);
    const [resultItems, setResultItems] = useState<Array<BackupStorageItem>>();
    const [isLoadingResult, setIsLoadingResult] = useState(false);
    const location = useLocation();
    const [isAuthError, setIsAuthError] = useState(false);

    const params = {
        lowerLimit: 2000,
        limit: 50
    };

    useEffect(() => {
        setIsLoadingResult(true);
        const getPost = async () => {
            try {
                const response = await axios.get(Routes.Bacukup.GetList, {
                    headers: {
                        Authorization: `Bearer ${loginItem.token}`,
                    }
                });
                setResultItems(response.data);
                setIsLoadingResult(false);
            }
            catch (error: any) {
                console.log(error.response);
                switch (error.response?.status) {
                    case 401:
                        setIsAuthError(true);
                        break;
                    default:
                        setIsLoadingResult(false);
                        break;
                }
            };
        }
        getPost();

    }, []);

    const NavigateLogin = () => {
        const origin = (location.state as any)?.from?.pathname || '/login';
        return <Navigate to={origin} />;
    }

    return (
        isAuthError ? NavigateLogin() :
            <MainTemplate2>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Title>バックアップファイル一覧</Title>

                    {isLoadingResult ?
                        <Box>
                            <Stack direction="row">
                                <Typography sx={{ mt: 1, mr: 2 }}>ファイル一覧取得中です...</Typography>
                                <CircularProgress />
                            </Stack>
                        </Box> :
                        <TableContainer sx={{ maxHeight: 700 }}>
                            <Table size="small" stickyHeader>
                                <TableBody>
                                    {resultItems &&
                                        resultItems.map(item =>
                                            <TableRow key={item.medicalInstitution?.code} hover>
                                                <TableCell>
                                                    <Stack direction="row">
                                                        {item.medicalInstitution?.code}　{item.medicalInstitution?.text}
                                                        <Typography color={getColor(item.status)}>　{item.statusText}</Typography>
                                                    </Stack>

                                                    <TableContainer>
                                                        <Table size="small" sx={{
                                                            [`& .${tableCellClasses.root}`]: {
                                                                borderBottom: "none"
                                                            }
                                                        }}>
                                                            <TableBody>
                                                                {item.entries?.map((entry, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell component="th" scope="row">
                                                                            {entry.folderName}
                                                                            <TableContainer>
                                                                                <Table size="small" sx={{
                                                                                    [`& .${tableCellClasses.root}`]: {
                                                                                        borderBottom: "none"
                                                                                    }
                                                                                }}>
                                                                                    <TableBody>
                                                                                        {entry.elements?.map((element, index) => (
                                                                                            <TableRow key={index}>
                                                                                                <TableCell width={300}>
                                                                                                    {element.fileName}
                                                                                                </TableCell>
                                                                                                <TableCell align='right' width={300}>
                                                                                                    {element.lengthKbText} KB
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        ))}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </TableCell>
                                                                        {/* <TableCell>{entry.length}</TableCell> */}
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </TableCell>
                                                {/* <TableCell>{item.monitoringResultId &&
                                                    <Link to={`../monitoring-result-detail/${item.monitoringResultId}`}>
                                                        詳細
                                                    </Link>}
                                                </TableCell>
                                                <TableCell>{item.medicalInstitution.text}</TableCell>
                                                <TableCell>
                                                    <Link to={`../monitoring-result-list-machine/${item.machineId}`}>
                                                        {item.machineId}
                                                    </Link>
                                                </TableCell> */}
                                                {/* <TableCell>
                                                    <Typography color={getColor(item.alertLevel.code)}>
                                                        {item.alertLevel.text}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>{item.monitoringResult}</TableCell>
                                                <TableCell>
                                                    <Typography color={getColor(item.alertLevel.code)}>
                                                        {item.alertMessage}
                                                    </Typography>
                                                </TableCell> */}


                                            </TableRow>)}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                </Paper>
            </MainTemplate2>
    );
};

const getColor = (status:number) => {

    switch (status) {
        case StatusType.NotFound:
        case StatusType.Stopped:
            return 'error';
        case StatusType.OutOfService:
            // 何故か 'warning' だと効かない
            return '#ED6C03';
        case StatusType.Normal:
            return 'blue';
        default:
            return 'Black';
    }
};