import { SettingItem, SettingRequest } from "../types/TypeDefinition";

export function GetKey(keyType:number, keyValue:string, sectionId:number, itemId:number) {
    return [keyType, keyValue, sectionId, itemId].join('^');
}

export function GetKeyFromItem(item: SettingItem) {
    return [item.keyType, item.keyValue, item.section.code, item.item.code].join('^');
}

export function SettingItemToRequest(item: SettingItem, accountId: number) {
    const request: SettingRequest = {
        keyType: item.keyType,
        keyValue: item.keyValue,
        sectionId: item.section.code,
        itemId: item.item.code,
        itemValue: item.itemValue ?? "",
        accountId: accountId
    };
    return request;
}


