import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from '../components/AuthContextProvider';
import { MainTemplate } from '../templates/MainTemplate';
import Paper from '@mui/material/Paper';
//import { FetchData } from ".FetchUtility";
import { Navigate, useLocation } from 'react-router-dom';
import * as Routes from "../ApiRoute"; 

export const EventLog = () => {
    const { token } = useAuth();
    const { id } = useParams();
    const [item, setItem] = useState([]);
    const location = useLocation();
    const [isAuthError, setIsAuthError] = useState(false);

    //権限チェック必要

    const params =
    {
        method: "GET",
        headers: {
            'Authorization': 'Bearer ' + token,
        },
    };

    const f = async () => {
        await fetch(`${Routes.EventLog.GetById}/${id}`, params)
            .then(data => data.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = "test.json";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                alert("download!");
            })
            .catch(() => alert("error"));
    };

    return (
        isAuthError ? <Navigate to={{ pathname: "/login", state: { from: location } }} /> :
            <MainTemplate>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <button onClick={f}>download</button>
                </Paper>
            </MainTemplate>
    )
};