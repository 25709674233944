// Base64 エンコードされた文字列を デコードします。
export function DecodeData(base64String: string) {

    // Base64 エンコードされた文字列を ArrayBuffer に変換します
   const base64 = base64String.replace(/-/g, '+').replace(/_/g, '/');
   const rawData = window.atob(base64);
   const buffer = new Uint8Array(rawData.length);
   for (let i = 0; i < rawData.length; i++) {
       buffer[i] = rawData.charCodeAt(i);
   }

   // デコード
   const decoder = new TextDecoder();
   const decodedData = decoder.decode(buffer);
   return JSON.parse(decodedData);
}
