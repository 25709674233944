import React, { useContext, useState } from 'react';
import { MyComponentProps, CodedElement } from '../types/TypeDefinition';

export const LoginItemKey = 'customerLoginItem';

export const AuthContext =
    React.createContext<AuthContextProps | null>(null);

export const useAuth = () => {
    const authContext = useContext(AuthContext);

    if (!authContext) {
        throw new Error("useAuth error");
    }
    return authContext;
};

type LoginItem = {
    account: CodedElement<number>,
    roleId: number,
    token: string
};

type AuthContextProps = {
    loginItem: LoginItem;
    setLoginItem: React.Dispatch<React.SetStateAction<LoginItem>>;
    handleLogout: () => void;
}

export const AuthProvider: React.FC<MyComponentProps> = (props) => {
    const [loginItem, setLoginItem] = useState(getLoginItem());

    const handleLogout = () => {
        setLoginItem(null);

        // ローカルストレージから削除
        window.localStorage.removeItem(LoginItemKey);
    };

    const value: AuthContextProps = {
        loginItem,
        setLoginItem,
        handleLogout,
    };

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    );
};

// ローカルストレージから読み込み
function getLoginItem() {
    try {
        const item = window.localStorage.getItem(LoginItemKey);
        if (item) {
            return JSON.parse(item);
        }
    }
    catch (e) {
        console.log(e);
    }
};


