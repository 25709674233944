import { AccountItem, AccountRequest} from "../types/TypeDefinition";

export function AccountItemToRequest(item: AccountItem, accountId: number) {
    const request: AccountRequest = {
        accountId: item.account.code,
        userId: item.userId,
        userName: item.account.text,
        password : item.password,
        userRoleId : item.userRoleId,
        emailAddress : item.emailAddress,
        alertMailSendType: item.alertMailSendType,
        updaterId : accountId
    };
    return request;
}
