import React from "react";
import { FComponentProps } from "./FComponentProps";

export const TableCellHead: React.FC<FComponentProps> = ({ className, children }) => {

    return (
        <th className={`h-auto px-2 py-1.5 text-sm text-gray-700 bg-gray-100 ${className}`}>
            {children}
        </th>
    )
};
