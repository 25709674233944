import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { Title } from '../../components/Title';
import formatDate from '../../templates/DateUtility';
import { MainTemplate2 } from '../../templates/MainTemplate2';
import { useAuth } from '../../components/AuthContextProvider';
import { TableContainer } from '@mui/material';
import { Link } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';
import { useParams, Navigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { DriveRecord, MonitoringResultItem, MyComponentProps } from '../../types/TypeDefinition';
import * as Routes from "../../ApiRoute";

export const MonitoringResultListForFacility : React.FC<MyComponentProps> = () => {
    const [items, setItems] = useState<Array<MonitoringResultItem>>();
    const { loginItem } = useAuth();
    const { medicalId } = useParams();
    const location = useLocation();
    const [isAuthError, setIsAuthError] = useState(false);

    useEffect(() => {
        const f = async ()=> {
            try {
                const response = 
                    await axios.get(`${Routes.MonitoringResult.GetListByMedicalId}/${medicalId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${loginItem.token}`,
                        }
                    });
                setItems(response.data);
            } catch (error: any) {
                console.log(error.response);
                switch (error.response?.status) {
                    case 401:
                        setIsAuthError(true);
                        break;
                    default:
                        break;
                }
            };
        };
        f();
    }, []);

    const params =
    {
        method: "GET",
        headers: {
            'Authorization': 'Bearer ' + loginItem.token,
        },
    };

    function getDriveInfo(driveRecords: Array<DriveRecord>, driveName: string) {
        const drive = driveRecords.find(record => record.name === driveName);
        if (drive == null) {
            return null;
        }
        return drive.freeSpace + " / " + drive.size + "GB";
    }

    const handleClick = async (id?: number)=> {
        //e.preventDefault();
        console.log(id);
        await fetch(`${Routes.EventLog.GetById}/${id}`, params)
        .then(data => data.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = `eventlog_${id}.zip`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(()=>alert("error"));
        };

        const NavigateLogin = () => {
            const origin = (location.state as any)?.from?.pathname || '/login';
            return <Navigate to={origin} />;
        }
    
        return (
            isAuthError ? NavigateLogin() :
        <MainTemplate2>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Title>監視結果履歴</Title>
                <TableContainer sx={{maxHeight:600}}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>端末ID</TableCell>
                            <TableCell>監視日時</TableCell>
                            <TableCell>再起動日時</TableCell>
                            <TableCell>Cドライブ</TableCell>
                            <TableCell>Dドライブ</TableCell>
                            <TableCell>イベントログ</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items?.map(item =>
                            <TableRow key={item.monitoringResultId} hover>
                                <TableCell>
                                    <Link className="text-green-650" to={`../monitoring-result-detail/${item.monitoringResultId}`}>
                                        詳細
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <Link className="text-green-650" to={`../monitoring-result-list-machine/${item.machineInfo.machineId}`}>
                                        {item.machineInfo.machineId}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    {item.monitoringDate
                                      ? formatDate(item.monitoringDate)
                                      : <WarningIcon />
                                    }
                                </TableCell>
                                <TableCell>
                                    {item.oS_BootUpTime &&
                                     formatDate(item.oS_BootUpTime)}
                                </TableCell>
                                <TableCell>
                                    {getDriveInfo(item.driveRecords, "C:\\")}
                                </TableCell>
                                <TableCell>
                                    {getDriveInfo(item.driveRecords, "D:\\")}
                                </TableCell>
                                <TableCell>
                                    {
                                        item.eventLogId &&
                                        <Button onClick={() => handleClick(item.eventLogId)}>{item.eventLogId}</Button>
                                    }
                                </TableCell>
                                {/*<TableCell>*/}
                                {/*    <Link to={`../eventlog/getlog/${item.イベントログ番号}`}>*/}
                                {/*        {item.イベントログ番号}*/}
                                {/*    </Link>*/}
                                {/*</TableCell>*/}
                            </TableRow>)}
                    </TableBody>
                </Table>
                </TableContainer>
            </Paper>
        </MainTemplate2>
    );
};

