import React from "react";

export type FInputComponentProps = {
    className?: string;
    placeholder?: string;
    name?: string,
    value?: string,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const FInput: React.FC<FInputComponentProps> = 
    ({className, placeholder, name, value, onChange}) => {
    
    return (
        <input className={`border p-2 rounded focus:border-green-700 ${className}`} 
        type="text" 
        name={name}
        value={value} 
        placeholder={placeholder}
        onChange={onChange}>
        </input>);
};


