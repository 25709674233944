import React, { useState, useEffect, ChangeEvent, FormEventHandler } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../components/AuthContextProvider';
import { MainTemplate2 } from '../../templates/MainTemplate2';
import {
    Button, Box, TableContainer, Table, TableRow, TableCell,
    TableHead, TableBody, TextField, Paper, ListItem
} from "@mui/material";
import { FetchData } from '../../utils/FetchUtility';
import { FetchPost } from '../../utils/FetchUtility';
import { Title } from '../../components/Title';
import { DataGrid, jaJP } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { SettingItem, MyComponentProps,
    MonitorProgramSettingTabStringRequest } from '../../types/TypeDefinition';
import * as Routes from "../../ApiRoute";    
import axios from 'axios';
import { GetKeyFromItem } from '../../functions/Setting';

export const SettingList: React.FC<MyComponentProps> = () => {
    const { loginItem } = useAuth();
    const [value, setValue] = useState("");
    const [keyword, setKeyword] = useState("");
    const [items, setItems] = useState<Array<SettingItem>>([]);
    const [filteredItems, setFilteredItems] = 
        useState<Array<SettingItem> | null>();
    const location = useLocation();
    const [isAuthError, setIsAuthError] = useState(false);
    const [request, SetRequest] = useState<MonitorProgramSettingTabStringRequest>();

    useEffect(() => {
        if (items?.length === 0) {
            const f = async () => {
                try {
                    const response = await axios.get(Routes.Setting.GetAll,  {
                        headers: {
                            Authorization: `Bearer ${loginItem.token}`,
                        }})
                        setItems(response.data);
                        setFilteredItems(response.data);
                }
                catch (error: any) {
                    console.log(error.response);
                    switch (error.response?.status) {
                        case 401:
                            setIsAuthError(true);
                            break;
                        default:
                            break;
                    }
                };
            };
            f();
        }

        const result = filterItems(keyword, items);

        if (keyword == null) {
            setFilteredItems(items ? items:null);
            return;
        }

        // 検索結果がゼロのときは空の配列(id列は必要)を返す
        setFilteredItems(result?.length ? result : null);

    }, [keyword]);

    // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();

    //     //base64文字列にエンコード(enescapeが非推奨だが別の書き方が見つからない)
    //     SetRequest({
    //         tabStringBase64: window.btoa(unescape(encodeURIComponent(value))),
    //         hasHeader: true,
    //         accountId: loginItem.account.code
    //     });

    //     FetchPost("api/monitorprogramSetting/register/tabstring", loginItem.token,
    //         request
    //     )
    // };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        setValue(target.value)
    };

    // // columns定義より上に書く必要がある
    // const renderEditButton = (params) => {
    //     return (
    //         <strong>
    //             <Button
    //                 variant="contained"
    //                 color="primary"
    //                 size="small"
    //                 component={Link}
    //                 to={`../monitor-program-setting-detail/${params.id}`}>
    //             編集</Button>
    //         </strong>
    //     )
    // };

    // // 列サイズ変更はPro版でないと使えない
    // const columns = [
    //     //{ field: 'id', headerName: 'ID', width: 90},
    //     { field: 'keyType', headerName: 'KeyType', width: 90 },
    //     { field: 'keyValue', headerName: 'KeyValue', width: 90 },
    //     { field: 'sectionId', headerName: 'SectionId', width: 90 },
    //     { field: 'itemId', headerName: 'ItemId', width: 90 },
    //     { field: 'itemValue', headerName: 'ItemValue', width: 300, editable: false },
    //     { field: 'editButton', headerName: '', width: 90, 
    //         renderCell: (params) => renderEditButton(params) }

    // ];

    const NavigateLogin = () => {
        const origin = (location.state as any)?.from?.pathname || '/login';
        return <Navigate to={origin} />;
    }

    return (
        isAuthError ? NavigateLogin() :           
        <MainTemplate2>
                {/* <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Title>監視プログラム設定</Title>
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={filteredItems}
                            getRowId={(row) => row.key}
                            columns={columns}
                            pageSize={20}
                            //components={{ Toolbar: GridToolbar }}
                            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </Box>
                </Paper> */}

                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Title>監視プログラム設定</Title>
                    <TextField id="field" color="secondary"
                        variant="outlined" label="絞り込み"
                        placeholder="[SectionId]-[ItemId] の形式で入力してください。例. 1-2"
                        onChange={(e) => setKeyword(e.target.value)} />
                    <TableContainer sx={{ maxHeight: 400 }}>
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{minWidth:100}}>KeyType</TableCell>
                                    <TableCell>KeyValue</TableCell>
                                    <TableCell>SectionId</TableCell>
                                    <TableCell sx={{minWidth:130}}>Section</TableCell>
                                    <TableCell>ItemId</TableCell>
                                    <TableCell sx={{minWidth:200}}>Item</TableCell>
                                    <TableCell sx={{minWidth:200}}>ItemValue</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredItems?.map(item =>
                                    <TableRow hover>
                                        <TableCell>{item.keyType}</TableCell>
                                        <TableCell>{item.keyValue}</TableCell>
                                        <TableCell>{item.section?.code}</TableCell>
                                        <TableCell>{item.section?.text}</TableCell>
                                        <TableCell>{item.item?.code}</TableCell>
                                        <TableCell>{item.item?.text}</TableCell>
                                        <TableCell>{item.itemValue}</TableCell>
                                        <TableCell>
                                            <Button
                                             color="primary"
                                             component={Link}
                                             to={`../setting-detail/${GetKeyFromItem(item)}`}>編集
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                             color="primary"
                                             component={Link}
                                             to={`../setting-copy/${GetKeyFromItem(item)}`}>複製
                                            </Button>
                                        </TableCell>
                                    </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box>
                                    <Button
                                     color="primary"
                                     variant='contained'
                                     component={Link}
                                     to="../setting-entry/">追加
                                    </Button>
                    </Box>
                </Paper>

                {/* <Box component="form" noValidate autoComplete='off'
                    onSubmit={handleSubmit}
                    sx={{ mt: 0, p: 1 }}>
                    <TextField sx={{ bgcolor: 'white' }}
                        margin="normal"
                        fullWidth
                        multiline
                        minRows={5}
                        id="tabText"
                        name="tabText"
                        placeholder="M_監視プログラム設定の内容を貼り付けてください。"
                        onChange={handleChange}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{ mt: 2, mb: 2 }}
                    >更新</Button>
                </Box> */}

            </MainTemplate2>
    );
};

const filterItems = (searchKeyword?:string, items?: Array<SettingItem>) => {

    if (!searchKeyword) {
        return items;
    }

    if (searchKeyword.indexOf('-') === -1) {
        // sectionIdのみで絞り込み
        return items?.filter((item) => 
            item.section.code === parseInt(searchKeyword));
    }

    const words = (searchKeyword).split("-");

    switch (words.length) {
        case 2:
            if (words[1]) {
                // sectionId, itemIdで絞り込み
                return items?.filter((item) => item.section.code === parseInt(words[0]) &&
                    item.item.code === parseInt(words[1]));

            }
            return items?.filter((item) => item.section.code === parseInt(words[0]));
    }
}

