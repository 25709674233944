import react, { useState, useEffect, ChangeEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../components/AuthContextProvider';
import { MainTemplate } from '../../templates/MainTemplate';
import {
    Button, Box, TableContainer, Table, TableRow, TableCell,
    TableHead, TableBody, TextField, Paper, Typography, InputLabel, Stack
} from "@mui/material";
import { AirlineSeatIndividualSuiteSharp, ErrorOutline, Label, SignalCellularConnectedNoInternet4BarRounded } from '@mui/icons-material';
//import { FetchData } from '../../components/FetchUtility';
//import { FetchPost } from '../../components/FetchUtility';
//import { FetchDelete } from '../../components/FetchUtility';
//import { Title } from '../../components/Title';
import { DataGrid, GridColDef, gridColumnsTotalWidthSelector, GridToolbar, jaJP } from '@mui/x-data-grid';
import { useParams } from "react-router-dom";
import { useNavigate, Link, Navigate, useLocation } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import { MachineType, MyComponentProps } from '../../types/TypeDefinition';
import { MainTemplate2 } from '../../templates/MainTemplate2';
import { MachineInfoItem } from '../../types/TypeDefinition';
import { ConfirmDialog } from '../../components/ConfirmDialog'
import * as Routes from "../../ApiRoute";
import { Title } from "../../components/Title"
import { ButtonType, FButton } from '../../components/FButton';
import { FTableRow } from '../../components/FTableRow';
import { FTable } from '../../components/FTable';
import { FTableBody } from '../../components/FTableBody';
import { TitleAndValue } from '../../types/TypeDefinition';

export const MachineInfoDetail: React.FC<MyComponentProps> = () => {
    const { loginItem } = useAuth();
    const [item, setItem] = useState<MachineInfoItem>();
    const { machineId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [isAuthError, setIsAuthError] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [rows, setRows] =  useState<Array<TitleAndValue> | null>();;

    useEffect(() => {
        const f = async () => {
            try {
                const response = await axios.get(`${Routes.MachineInfo.GetById}/${machineId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${loginItem.token}`,
                        }
                    });
                const itemData: MachineInfoItem = response.data;
                setItem(response.data);

                let rowsData:Array<TitleAndValue> = [];

                rowsData.push({ title: 'PC名', value: itemData.pcName })
                rowsData.push({ title: 'IPアドレス', value: itemData.ipAddress })
                rowsData.push({ title: 'ドメイン', value: itemData.domainName })
                rowsData.push({ title: '利用種別', value: itemData.machineType?.toString() })
                rowsData.push({ title: '設置場所', value: itemData.location })
                rowsData.push({ title: '機器名', value: itemData.modelName })
                rowsData.push({ title: 'CPU', value: itemData.cpu })
                rowsData.push({ title: 'メモリ容量', value: `${itemData.memory?.toString()}GB`})
                rowsData.push({ title: 'ストレージ種類', value: itemData.storageType?.toString() })
                rowsData.push({ title: 'ストレージ容量', value: itemData.storage?.toString() })
                rowsData.push({ title: 'ディスクドライブ', value: itemData.diskDrive })
                rowsData.push({ title: 'OS_インストール日時', value: itemData.oS_InstallDate?.toString() })
                rowsData.push({ title: 'OS_名称', value: itemData.oS_Name })
                rowsData.push({ title: 'OS_バージョン', value: itemData.oS_Version })
                rowsData.push({ title: 'OS_ビルド番号', value: itemData.oS_BuildNumber })
                rowsData.push({ title: 'OS_SP_バージョン', value: itemData.oS_SP_Version })
                rowsData.push({ title: 'OS_シリアル番号', value: itemData.oS_SerialNumber })

                rowsData.push({ title: 'モニターサイズ', value: itemData.monitorSize })
                rowsData.push({ title: 'リモートソフト', value: itemData.remoteSoftware })
                rowsData.push({ title: 'オフィスバージョン', value: itemData.mSOfficeVersion })
                rowsData.push({ title: 'SQLServerバージョン', value: itemData.sqlServerVersion })
                rowsData.push({ title: 'SQL復旧モデル', value: itemData.sqlRecoveryModel })
                rowsData.push({ title: 'DotNetバージョン', value: itemData.dotNetVersion })
                rowsData.push({ title: '製造番号', value: itemData.identifyingNumber })
                rowsData.push({ title: '保守番号', value: itemData.supportNumber })
                rowsData.push({ title: '保守形態', value: itemData.supportType })
                rowsData.push({ title: '保守期限', value: itemData.supportExpirationDate?.toString() })
                rowsData.push({ title: '購入年月日', value: itemData.purchaseDate?.toString() })
                rowsData.push({ title: '購入者', value: itemData.purchaser })
                rowsData.push({ title: 'ライセンスキー', value: itemData.licenseKey })
                rowsData.push({ title: '最終使用日時', value: itemData.lastUsedDate?.toString() })
                rowsData.push({ title: '備考', value: itemData.note })

                if (itemData.machineType !== MachineType.Client) {
                  rowsData.push({ title: '監視状態', value: GetMonitoringStatusText(itemData.monitoringFlag) });
                }

                rowsData.push({ title: '削除フラグ', value: itemData.deleteFlag?.toString()})
                rowsData.push({ title: '登録日時', value: itemData.insertDate?.toString()})
                rowsData.push({ title: '登録者', value: itemData.insertUser?.toString()})
                rowsData.push({ title: '更新日時', value: itemData.updateDate?.toString()})
                rowsData.push({ title: '更新者', value: itemData.updateUser?.toString()})

                setRows(rowsData);
            }
            catch (error: any) {
                console.log(error.response);
                switch (error.response?.status) {
                    case 401:
                        setIsAuthError(true);
                        break;
                    default:
                        break;
                }
            }
        };
        f();
    }, []); 

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     await FetchPost("../api/monitorprogramSetting/register", loginItem.token,
    //         item
    //     );

    //     // 元のページに戻る
    //     navigate(-1);
    // };

    // const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    //     const target = e.target;
    //     setItem({
    //         ...item,
    //         itemValue: target.value
    //     })
    // };

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

   // 削除処理
   const handleDelete = async () => {

        try {
            const response = await axios.delete(`${Routes.MachineInfo.DeleteById}/${machineId}`,
                {
                    headers: {
                        Authorization: `Bearer ${loginItem.token}`,
                    }
                });
            console.log(response.data);

            // 削除後はモーダルを閉じる
            closeModal();
            navigate(-1);
        } catch (error: any) {
            console.error(error);
        }
   };
 

   const handleChangeMonitoringFlag = (flag:boolean) => async () => {
    try {
        const response = await axios.post<boolean>(
            `${Routes.MachineInfo.ChangeMonitoringFlag}/${machineId}?value=${flag}`,"",
            {
                headers: {
                    Authorization: `Bearer ${loginItem.token}`,
                }
            });
        console.log(response.data);

        // APIのレスポンスを用いてmonitoringFlagを更新
        if (item) {
            setItem({...item, monitoringFlag: response.data});
        }

    } catch (error: any) {
        console.error(error);
    }

   };

const NavigateLogin = () => {
    const origin = (location.state as any)?.from?.pathname || '/login';
    return <Navigate to={origin} />;
}

return (
    isAuthError ? NavigateLogin() :
        <MainTemplate2>
        {item && 
            <div>
                <div className="flex justify-between items-center px-2 py-2">
                <Title className='ml-2'>端末情報 {machineId}</Title>
                <div className='flex'>

                 {item.machineType !== MachineType.Client &&
                 <FButton onClick={handleChangeMonitoringFlag(!item.monitoringFlag)}
                    buttonType={ButtonType.Carefullly}>
                    {item.monitoringFlag ? ' 監視停止':'監視再開'}
                </FButton>}

                <FButton onClick={openModal} buttonType={ButtonType.Carefullly}>削除</FButton>
                </div>
                </div>
            <div className="md:mx-auto w-full 
                 bg-white border rounded-lg shadow
                 flex flex-col justify-between items-stretch">

                <div className="flex justify-between items-center px-4 py-2">
                </div>

                    <FTable>
                        <FTableBody>
                            {/* {item.machineType !== MachineType.Client &&
                                <FTableRow>
                                    <TableCell>監視状態</TableCell>
                                    <TableCell>{GetMonitoringStatusText(item.monitoringFlag)}</TableCell>
                                </FTableRow>
                            }
                            <FTableRow>
                                <TableCell>.net</TableCell>
                                <TableCell>{item.dotNetVersion}</TableCell>
                            </FTableRow> */}
                            {rows?.map((row, index) =>(
                                <FTableRow key={index}>
                                    <TableCell>{row.title}</TableCell>
                                    <TableCell>{row.value}</TableCell>
                                </FTableRow>
                            ))}
                        </FTableBody>
                    </FTable>

                <ConfirmDialog isOpen={isOpen} onClose={closeModal} onDelete={handleDelete}>
                    端末情報を削除します。この端末の情報は全て削除されます。
                    よろしいですか。
                </ConfirmDialog>

            </div>
            </div>
        }
        </MainTemplate2>
);
};

function GetMonitoringStatusText(flag: boolean) {
    if (flag) {
        return "監視中";
    }
    else {
        return "監視停止中";
    }
}
