import { NavLink } from 'react-router-dom';
import { MainTemplate2 } from '../templates/MainTemplate2';
import { List, ListItem, Typography } from "@mui/material";
import { MyComponentProps } from '../types/TypeDefinition';

export const AdministratorSetting: React.FC<MyComponentProps> = () => {

    // 認証チェック、ロールのチェックが必要

    return (
        <>
            <MainTemplate2>
                <Typography>システム設定</Typography>
                <List>
                    <ListItem>
                        <NavLink className="text-green-650" to="/account-list">ユーザー一覧</NavLink>
                    </ListItem>
                    <ListItem>
                        <NavLink className="text-green-650" to="/setting-list">設定</NavLink>
                    </ListItem>
                    <ListItem>
                        <NavLink className="text-green-650" to="/storage-upload">監視プログラムのアップロード</NavLink>
                    </ListItem>
                </List>
            </MainTemplate2>
        </>
    );
};

