import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../components/AuthContextProvider';
import { MainTemplate } from '../../templates/MainTemplate';
import {
    Button, Box, TableContainer, Table, TableRow, TableCell,
    TableHead, TableBody, TextField, Paper, Typography, InputLabel
} from "@mui/material";
import { AirlineSeatIndividualSuiteSharp, Label, SignalCellularConnectedNoInternet4BarRounded } from '@mui/icons-material';
//import { FetchData } from '../FetchUtility';
import { FetchPost } from '../../utils/FetchUtility';
import { Title } from '../../components/Title';
import { DataGrid, GridColDef, GridToolbar, jaJP } from '@mui/x-data-grid';
import { useParams } from "react-router-dom";
import { useNavigate, Link, Navigate, useLocation } from "react-router-dom";
import axios from 'axios';
import * as Routes from "../../ApiRoute";
import { MyComponentProps } from '../../types/TypeDefinition';
import { MainTemplate2 } from '../../templates/MainTemplate2';

export const SettingEntry : React.FC<MyComponentProps> = () => {
    const { loginItem } = useAuth();
    const [item, setItem] = useState({ key: "", keyType: "", keyValue: "", sectionId: "", itemId: "", itemValue: "" });
    const [value, setValue] = useState();
    const { key } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [isAuthError, setIsAuthError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let isError = false;
        
            try {
                const response = await axios.post(Routes.Setting.Register, item, {
                    headers: {
                        Authorization: `Bearer ${loginItem.token}`,
                    }
                });
                setItem(response.data);
            } catch (error: any) {
                console.log(error.response);
                setErrorMessage("設定登録処理でエラーが発生しました。");
                isError = true;
                switch (error.response?.status) {
                    case 401:
                        setIsAuthError(true);
                        break;
                    default:
                        break;
                }
            };
        
            if (!isError) {
                // 元のページに戻る
                navigate(-1);
            }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            [e.target.name]: e.target.value
        })
    };

    const NavigateLogin = () => {
        const origin = (location.state as any)?.from?.pathname || '/login';
        return <Navigate to={origin} />;
    }
    
    return (
        isAuthError ? NavigateLogin() :
            <MainTemplate2>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Title>設定追加</Title>
                    <div>
                        <Box component="form" noValidate autoComplete='off'
                            onSubmit={handleSubmit}
                            sx={{ mt: 0, p: 1 }}>

                            <TableContainer sx={{ maxHeight: 400 }}>
                                <Table size="small" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>KeyType</TableCell>
                                            <TableCell>KeyValue</TableCell>
                                            <TableCell>SectionId</TableCell>
                                            <TableCell>ItemId</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key={item.key} hover>
                                            <TableCell>
                                                <TextField sx={{ bgcolor: 'white' }}
                                                    margin="none"
                                                    id='keyType'
                                                    name='keyType'
                                                    value={item.keyType}
                                                    onChange={handleChange}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField sx={{ bgcolor: 'white' }}
                                                    margin="none"
                                                    id='keyValue'
                                                    name='keyValue'
                                                    value={item.keyValue}
                                                    onChange={handleChange}
                                                /></TableCell>
                                            <TableCell>
                                                <TextField sx={{ bgcolor: 'white' }}
                                                    margin="none"
                                                    id='sectionId'
                                                    name='sectionId'
                                                    value={item.sectionId}
                                                    onChange={handleChange}
                                                /></TableCell>
                                            <TableCell>
                                                <TextField sx={{ bgcolor: 'white' }}
                                                    margin="none"
                                                    id='itemId'
                                                    name='itemId'
                                                    value={item.itemId}
                                                    onChange={handleChange}
                                                /></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography component="h4"
                                sx={{ mt: 3, mb: 1 }}>ItemValue</Typography>
                            <TextField sx={{ bgcolor: 'white' }}
                                margin="none"
                                fullWidth
                                id='itemValue'
                                name='itemValue'
                                value={item.itemValue}
                                onChange={handleChange}
                            />
                            <Button
                                variant="contained"
                                component={Link}
                                color="secondary"
                                to="../setting-list"
                                sx={{ mt: 2, mb: 2 }}
                            >キャンセル</Button>

                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 2, mb: 2, ml: 1 }}
                            >保存</Button>
                        </Box>
                    </div>
                </Paper>

                {errorMessage &&
                            <div className="m-2 bg-red-100 border border-red-400 text-sm
                         text-red-700 px-2 py-1 rounded relative" role="alert">
                                <span className="block sm:inline">{errorMessage}</span>
                            </div>}
            </MainTemplate2>
    );
};


