import { useEffect, useState } from 'react';
import { MainTemplate } from '../templates/MainTemplate';
import { List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import SettingsIcon from '@mui/icons-material/Settings';
import ErrorIcon from '@mui/icons-material/Error';
import { Title } from '../components/Title';
import { Navigate, useLocation } from 'react-router-dom';
import { MainTemplate2 } from '../templates/MainTemplate2';
import { useAuth } from '../components/AuthContextProvider';

export const Home = () => {
    const location = useLocation();
    const { loginItem } = useAuth();
    const [isAuthError, setIsAuthError] = useState(false);

    //---- ここにトークンのチェック処理が必要


    const NavigateLogin = () => {
        const origin = (location.state as any)?.from?.pathname || '/login';
        return <Navigate to={origin} />;
    }


    return (
        !loginItem?.token ? NavigateLogin():
            <MainTemplate2>
                <h3>ホーム</h3>

                <Paper sx={{p:2}}>
                    <Title>画面説明</Title>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <LocalHospitalIcon />
                            </ListItemIcon>
                            <ListItemText primary="fカルテットの顧客一覧を表示します。" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ErrorIcon />
                            </ListItemIcon>
                            <ListItemText sx={{color:'blue'}} primary="異常検知内容の一覧を表示します。" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <DesktopWindowsIcon />
                            </ListItemIcon>
                            <ListItemText primary="サーバー監視結果の一覧を表示します。" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>設定</ListItemIcon>
                            <ListItemText primary="fカルテットの設定状況を表示します。"
                                secondary="（開発中）"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="システム管理者メニュー"
                                secondary="監視プログラムのアップロードなどを行います。管理者以外は使用しないでください。" />
                        </ListItem>
                    </List>
                </Paper>

                <Paper sx={{ p:2, mt:2 }}>
                    <Typography>制限事項、未実装機能</Typography>
                       <ul>
                        <Typography component="li" variant='body1' color='red'>随時プログラム修正を行っているため、Webサーバーを停止する場合があります。繋がらない場合は5～10分経ってから再接続してください。</Typography>
                        <li>ユーザー登録機能はありません(システム管理者にオフラインで登録申請してください)。</li>
                        <li>パスワード変更機能はありません。</li>
                        <li>ユーザーロール機能はありません。(全ユーザーが全機能を使用できますが、管理者以外は管理者メニューを実行しないでください。)</li>
                    </ul>
                </Paper>

            </MainTemplate2>
    );
};
