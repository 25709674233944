import { useState } from "react";
import axios from "axios";
import { useAuth } from "../components/AuthContextProvider";

// ファイルアップロード処理
export const useUploadForm = (url: string) => {
    const { loginItem } = useAuth();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const uploadForm = async (formData:FormData) => {
        setIsLoading(true);
        setIsSuccess(false);
        setProgress(0);

        await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${loginItem.token}`,
            },
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded) * 100 / progressEvent.total);
                setProgress(progress);
                console.log(progress);
            },
        });

        setIsSuccess(true);
        setIsLoading(false);
        console.log("success");
    };

    return { uploadForm, isSuccess, isLoading, progress };
};
